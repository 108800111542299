/* =================================================

Name: MGI Connect
Author: MGI Digital Technology, MGI Labs
Version: 1.0
Design and Developed by: MGI Labs

NB: Ce fichier contient le style de l'interface MGI Connect

=================================================== */
@import "./variable.scss";
::-webkit-scrollbar {
  display: none;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 200;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "worksans";
  src: local("worksans"),
    url(../../fonts/worksans/WorkSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "worksans";
  font-weight: 200;
  src: local("worksans"),
    url(../../fonts/worksans/WorkSans-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "worksans";
  font-weight: 300;
  src: local("worksans"),
    url(../../fonts/worksans/WorkSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: "worksans";
  font-weight: 500;
  src: local("worksans"),
    url(../../fonts/worksans/WorkSans-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "worksans";
  font-weight: 600;
  src: local("worksans"),
    url(../../fonts/worksans/WorkSans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "worksans";
  font-weight: 700;
  src: local("worksans"),
    url(../../fonts/worksans/WorkSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "montserrat";
  font-weight: 200;
  src: local("montserrat"),
    url(../../fonts/Montserrat/Montserrat-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "montserrat";
  font-weight: 300;
  src: local("montserrat"),
    url(../../fonts/Montserrat/Montserrat-Light.ttf) format("truetype");
}

@font-face {
  font-family: "montserrat";
  font-weight: 400;
  src: local("montserrat"),
    url(../../fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "montserrat";
  font-weight: 500;
  src: local("montserrat"),
    url(../../fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "montserrat";
  font-weight: 600;
  src: local("montserrat"),
    url(../../fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "montserrat";
  font-weight: 700;
  src: local("montserrat"),
    url(../../fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "montserrat";
  font-weight: 800;
  src: local("montserrat"),
    url(../../fonts/Montserrat/Montserrat-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "montserrat";
  font-weight: 900;
  src: local("montserrat"),
    url(../../fonts/Montserrat/Montserrat-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Young";
  src: local("Young"), url(../../fonts/Young/Young.ttf) format("truetype");
}

html {
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-color: var(--backgroundaccount);
}
body {
  height: 100%;
  width: 100%;
}
a {
  color: var(--colortext);
  text-decoration: none;
}
a:hover {
  color: var(--colortext);
  text-decoration: none;
  text-transform: none;
}
.home {
  height: 100%;
  width: 100%;
  padding: 0;
  background-color: var(--bkgconnect);
  color: var(--colortext) !important;
  display: flex;
}
.containerzone {
  flex-wrap: wrap;
  overflow: hidden;
  background-color: var(--bkgconnect);
  height: calc(100vh - 0px);
  align-content: flex-start;
  width: 100%;
  position: relative;

  .navbarzone {
    width: 100%;
    position: relative;
    height: 60px;
    .btnmenu{
      display: none;
    }
    @media (max-width:720px){
      .btnmenu{
        width: 20px;
        height: 20px;
        position: absolute;
        left: 35px;
        top: 20px;
        display: flex;
        z-index: 1000;
        svg{
          width: 100%;
          height: 100%;
          fill: var(--B_W);
        }
      }
    }
  }
  .heightbottom {
    height: 34px;

    bottom: 0;
    position: absolute !important;
    right: 0;
    padding-top: 2px !important;
    padding-bottom: 0 !important;
    width: 100%;
    padding-right: 35px !important;
    display: flex;
    align-items: flex-start;
    background-color: var(--bkgconnect);
    z-index: 100;
    justify-content: flex-end;
    a {
      font-size: calc(var(--veryveryverylittlefont));
      opacity: 0.6;
    }
  }
}
.createaccountzone {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;


}
