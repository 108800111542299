@import "./homepage.module.scss";
.Container {
  flex-wrap: wrap;
  overflow: scroll;
  display: block;
  background-color: var(--bkgconnect);
  height: calc(100vh - 70px);
  // width: 84vw;
  width: 100%;

  p {
    font-family: "worksans";
  }
  justify-content: space-between;
  flex-wrap: nowrap;
  // margin-left: 16vw;
  padding: 0 30px;
  padding-bottom: 30px;
  @media (max-width: 1250px) {
    // margin-left: 200px;
    // width: calc(100vw - 200px);
  }

  @media (min-width: 1552px) {
    // margin-left: 240px;
    // width: calc(100vw - 240px) !important;
  }

  .Gestion_User {
    height: 100%;
    overflow: hidden;
    position: relative;
    padding-left: 0px;
    padding-right: 5px;

    table {
      height: calc(100%);
      overflow: hidden;
      tbody {
        height: calc(100% - 90px);
        overflow: hidden;
        overflow-x: hidden;
        overflow-y: scroll;
      }
    }
  }
  .packdetail {
    // height: calc(calc(100% - 120px) + 3vh);
    height: 100%;

    overflow: scroll;
    padding-left: 5px;
    padding-right: 5px;
  }

  .abonnementactif {
    float: left;
    width: 100%;

    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    padding: 5px;
    margin: 0;
    flex-wrap: wrap;
    // min-height: 60%;
    // height: 390px;
    // @media (min-height: 800px) {
    //   min-height: auto !important;
    //   height: 410px;
    // }

    .Allproject_projects_section_sort {
      width: 100%;
      padding-left: 15px !important;
      padding-right: 15px !important;

      background-color: var(--tile-bckg);
      overflow: visible;
      .Allproject_projects_sort {
        color: var(--B_W);
      }
    }
    .packimgtext {
      background-color: var(--tile-bckg);
      width: 100%;
      height: calc(100% - 34px);
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .packimg {
        width: calc(50% - 5px);
        height: calc(100%);
        background-color: #3f85cb;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        overflow: hidden;

        img {
          width: 100%;
          max-height: 100%;
          object-fit: contain;
          margin: auto;
        }
      }
      .packtextzone {
        width: calc(50% - 5px);
        height: calc(100%);
        border: 1px solid var(--borderbottom);
        position: relative;
        .packtitle {
          width: 100%;
          height: 35px;
          padding-left: 10px;
          padding-right: 10px;
          border-bottom: 1px solid var(--borderbottom);
          display: flex;
          align-items: center;
          p {
            margin: 0;
          }
        }
        .btnrenouvelerabo{

          display: flex;
          justify-content: center;
          align-content: center;
          width: calc(100% - 30px);
          left: 15px;
          position: absolute;
          bottom: 15px;
          margin: auto;
          border: 1px solid var(--separateur_horizontal);
          height: 35px;
          align-items: center;
          cursor: pointer;
          opacity: 0.8;
          &:hover{
            color: var(--B_W) !important;
            opacity: 1;
          }
        }
      }
      .packtext {
        padding: 10px;
        .onepacktext {
          width: 100%;
          padding-bottom: 7px;
          padding-top: 7px;
          .opacity {
            opacity: 0.7;
          }
          p {
            margin: 0;
          }
        }
        .buttonpack {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 10px;
          padding-top: 10px;
          padding-right: 10px;
          position: absolute;
          bottom: 0;
          width: 100%;
          right: 0;
          .onebutton {
            border: 1px solid var(--borderbottom);
            width: 100px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            p {
              margin: 0;
              padding-top: 5px;
              padding-bottom: 5px;
            }
          }
          .onebutton:hover {
            border: 1px solid var(--bluehover);
            p {
              color: var(--B_W);
            }
          }
          .onebutton:active {
            border: 1px solid var(--blueactive);
          }
        }
      }
    }
  }

  .leftgraph {
    width: 50%;
    //height: calc(235px + 3vh);
    /* padding: 0; */

    padding: 5px;
    float: left;
  }
  .rightgraph {
    width: 50%;
    // height: calc(235px + 3vh);
    /* padding: 0; */
    padding: 5px;

    float: left;
  }
}
.tablecontainer {
  //border: 1px solid var(--separateur_horizontal);
  border: none;
  border-radius: 4px;

  overflow: scroll;
  position: initial !important;
  overflow: visible;
  float: right;
  // height: calc(calc(100% - 120px) + 3vh);
  @media (min-height: 820px) {
    //    height: calc(645px + 3vh);
    position: relative;
  }
  padding-right: 5px !important;
  padding-left: 5px !important;
  padding: 5px;

  &::-webkit-scrollbar {
    display: block !important;
    width: 0px;
    height: 4px;
    overflow: auto;
    background: var(--bckg_scrollbar);
  }
}
.tablecontainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--scrollbar);
}

.title {
  height: 50px;

  h2 {
    font-size: var(--verybigfont);
    font-family: "Poppins";
    font-weight: 500;
    white-space: nowrap;
    margin-bottom: 28px;
  }
}

@import "./variable.scss";
//@import "./annimations/Allprojects_annimantions.scss";
// Class d'appel
.agrandir_number {
  width: 100%;
  animation-duration: 0.3s;
  animation-name: agrandirN;
}
.reduire_number {
  width: 71%;
  animation-duration: 0.3s;
  animation-name: reduireN;
}
.agrandir_allproject {
  width: 100%;
  animation-duration: 0.3s;
  animation-name: agrandir;
}
.reduire_allproject {
  width: 70%;
  animation-duration: 0.3s;
  animation-name: reduire;
}
// ----------------------
.Allproject_projects {
  height: 100%;
  background-color: var(--colorprinc);
  padding-top: 2px;
}
.Allproject_projects_filter_hide {
  height: calc(100% - 50px);

  padding-top: 20px;
}
.bottom {
  margin: 0;
  padding: 0;
  height: calc(100%);
  overflow: auto;
}
.bottom_show {
  height: calc(100% - 33px) !important;
}
.Allproject_title {
  font-size: calc(15px + 0.6vw);
  font-weight: 500;
  margin-bottom: calc(15px + 0.6vw);
  font-family: "worksans";
}

//STATES Current all archived
.paddingleft {
  padding-left: 15px;
}
.Allproject_state {
  text-align: center;
  border: 1.5px solid #dee1e6;
  margin-bottom: 10px;
  border-radius: 12px;
  padding: 0;
  height: 28px;
  display: flex;
  cursor: pointer;
  button {
    background: none;
    border: none;
    color: #777777;
    font-size: $font8_3;
    font-family: "worksans";
  }
  .Allproject_state_select {
    width: 100%;
    margin: -1.5px;
    border: 1.5px solid #dee1e6;
    border-radius: 12px;
    cursor: default;
  }
}

.Allproject_projects_content {
  margin: 0 !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  height: calc(100% - 99px);
  display: block;
  overflow: scroll;
  align-content: flex-start;
  margin-bottom: 4px;
  overflow: scroll;
  border-bottom: 1px solid var(--separateur_horizontal);
}
.Allproject_projects_content_no_button {
  height: calc(100% - 34px) !important;
}
.Tbl_Gestion_User {
  padding-right: 0px !important;
  padding-left: 0px !important;
  height: 100%;
  background-color: var(--tile-bckg);
  .deleteuser {
    position: absolute;
    right: 0px;
    bottom: -30px;
    cursor: pointer;

    border: 1px solid var(--B_W);
    p {
      margin: 0;
      padding-right: 10px;
      padding-left: 10px;
      font-family: "worksans";
      font-size: var(--veryveryverylittlefont) !important;
    }
  }
  .desactivated {
    display: none;
    cursor: default;
  }
}
.Allproject_projects_content::-webkit-scrollbar-thumb {
  border-right-width: 5px;
  border-radius: 30px;
  background-color: #d9d9d9;
  /* border-top-right-radius: 9px 5px; */
  /* border-bottom-right-radius: 9px 5px; */
}
.Allproject_projects_content::-webkit-scrollbar-thumb:hover {
  background-color: #aaaaaa;
}

.Allproject_project_section {
  margin: 0;
  height: 100%;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}
.Allproject_projects_section_sort {
  text-align: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
  display: flex;
  padding: 2px;
  border-bottom: 1px solid var(--borderbottom);
  border-radius: 4px 4px 0px 0px;

  font-size: $font9;
  font-family: "worksans";
  font-weight: 400;
  color: black;
  align-items: center;
  height: calc(34px);
  margin: 0 !important;

  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--verylittlefont);
  position: relative;
  overflow: visible;

  p {
    margin: 0;
    color: var(--B_W);
    font-size: var(--verylittlefont);
  }
  .sort_options {
    width: calc(22px + 0.4vw);

    display: flex;
    align-items: center;
    position: absolute;
    left: 3.4%;
    z-index: 1;
    button {
      border: 1px solid #777777;
      background-color: white;
      width: 100%;
      height: calc(19px + 0.4vw);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      padding: 0;
      margin-top: -14px;
      img {
        width: 45%;
      }
    }
  }
  .sort_list_active {
    display: block !important;
  }
  .sort_list {
    display: none;
    background-color: white;
    box-shadow: 3px 5px 7px #d4d4d4;
    li {
      padding-left: 15px;
      padding-top: 7px;
      padding-bottom: 7px;
      cursor: pointer;
    }
    li:hover {
      color: $blue;
      transform: scale(1.01);
    }
    .sort_active {
      color: $blue;
    }
  }
}

// ajouter la bordure bleu lors du clic sur un projet
.Allproject_project_active {
  border: 1px solid #d5dbea !important;
  background-color: #d7e0ffde !important;
  font-weight: 500 !important;
  font-family: "worksans";
  color: black;
  span {
    background-color: #dbe4ff !important;
    a:hover {
      background-color: #ccd9ff;
    }
    a:active {
      background-color: #b6c6f8;
    }
  }
}

.Allproject_project {
  margin: 0;
  height: calc(20px + 1.3vw);
  display: flex;
  align-items: center;
  position: relative;

  font-size: calc(9px + 0.45vw);
  font-family: "worksans";
  font-weight: 500;
  color: rgba(0, 0, 0, 0.95);
  //  max-height: 4vw;

  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "worksans";

  border-bottom: 1px solid var(--separateur_horizontal);
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;

  margin: 0 !important;
  height: auto;
  overflow: visible;
  // Nouvelle gestion utilisateur
  width: 100%;
  // justify-content: space-between;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap !important;
  border-radius: 0px;

  div {
    padding: 0;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.Allproject_padding {
  width: 100%;
  display: flex;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0px;
  height: 100%;
  .deleteuser {
    img {
      filter: var(--invert2);
      opacity: 0.8;
    }
    img:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
  .titlewidth {
  }
  .Allproject_projects_sort {
    text-align: center;
    border: none;

    display: flex;
    align-items: center;

    height: 100%;

    font-size: var(--verylittlefont);
    font-weight: 400;
    color: var(--colortext);
    font-family: "worksans";
    padding-left: 0;
    padding-right: 0;
    &IMG {
      margin-left: 10px;
    }
    p {
      color: var(--colortext);
      font-size: var(--veryverylittlefonttable);
      font-weight: 500;
      margin: 0;
      padding: 0;
      font-family: "worksans";
    }
  }
  .Allproject_projects_sort:first-child {
    padding-left: calc(5px) !important;
    padding-right: calc(5px) !important;
  }
  .blue {
    color: var(--bluetext);
  }
  .Allproject_projects_sort:last-child {
    border-right: 1.5px solid transparent;
  }
  button {
    background-color: var(--colorprinc);
    border-right: 1.5px solid #dee1e6;
    border: none;
    font-family: "worksans";
  }
}
.papercontent {
  display: flex;
  flex-wrap: wrap;
  p {
    margin: 0;
    padding: 0;
  }
}
.colonemod {
  display: flex;
  height: 100%;
  padding-right: 0;
  padding-left: 0;
  justify-content: center;
  align-items: center;
  p {
    padding-left: 2px;
    padding-right: 2px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .border_right {
    border-right: solid 1px #505050;
  }
}

th.papercontent {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0px !important;
  padding-right: 0px !important;
  .Paper {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #505050;
  }
  .middle {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #505050;
    border-right: 1px solid #505050;
  }
  .Minipaper {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.Allproject_project_date {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 5vw !important;
  padding-left: 3px;
}
@media (min-width: 1600px) {
  .Allproject_project_date {
    padding-right: 5vw !important;
  }
}
@media (max-width: 1600px) {
  .Allproject_project_date {
    padding-right: 3vw !important;
  }
}
@media (max-width: 900px) {
  .Allproject_project_date {
    padding-right: 1.5vw !important;
  }
}
//CHECKBOX

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  border-radius: 2px;
  outline: none;
  border: 1px solid #afafaf;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  left: 0;
  position: relative;
  cursor: pointer;
  filter: var(--checkinvert) !important ;
}
[type="checkbox"]:not(:checked) {
  background-color: transparent;
}
[type="checkbox"]:checked {
  background-image: url(../../images/icon/check.svg);
  background-size: 95%;
  filter: invert(1);
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid #444444;
  padding: 0;
}

.bat_checkmark {
  height: calc(13.5px + 0.28vw);
  width: calc(13.5px + 0.28vw);
}
.Allproject_project_step {
  display: flex;
  flex-wrap: wrap;
  padding-right: 20px !important;
  padding-left: 20px !important;
}
//Couleurs des bulets status
.Allproject_project_step_start {
  border: 1px solid #afafb0;
  height: calc(11px + 0.4vw);
  width: calc(11px + 0.4vw);
  border-radius: 100px;
}
.Allproject_project_step_start_active {
  background-color: $blue;
}
.Allproject_project_step_wait {
  border: 1px solid black;
  height: calc(11px + 0.4vw);
  width: calc(11px + 0.4vw);
  border-radius: 100px;
  margin: auto;
}
.Allproject_project_step_wait_active {
  background-color: #ffc000;
  border: none;
}

.Allproject_project_step_ready_active {
  background-color: #4149f6;
  border: none;
}

.Allproject_project_step_end_active {
  background-color: #38d0bf;
  border: none;
}

.Allproject_project_step_end {
  border: 1px solid #afafb0;
  height: calc(11px + 0.4vw);
  width: calc(11px + 0.4vw);
  border-radius: 100px;
  margin: auto;
}
.Allproject_project_step_end_approuve {
  background-color: #2efdbd;
}
.Allproject_project_step_decline_active {
  background-color: red;
}
.Allproject_project_action,
.Allproject_project_action:hover {
  color: black;
  padding-left: calc(7px + 0.1vw);
  padding-right: calc(7px + 0.1vw);
  padding-top: calc(3px + 0.1vw);
  padding-bottom: calc(3px + 0.1vw);
  font-size: $font9;
  font-weight: 500;
  cursor: pointer;
  font-family: "worksans";
  border: 1px solid black;
  border-radius: 6px;
}

.Allproject_project_add {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  height: 0px !important;
  margin: 0;
}
.Allproject_project_Addicon {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: default;
  .Allproject_project_icon {
    cursor: pointer;
    border-radius: 500px;
    height: calc(35px + 1vw);
    width: calc(35px + 1vw);

    font-size: calc(18px + 1vw);
    border: 1px solid $blue;
    //  background-image: url(../../../images/projects/plus2.svg);
    background-color: $blue;
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: -28px;

    position: fixed;
    bottom: 35px;

    a {
      color: black;
      font-family: "worksans";
    }
    .Allproject_project_iconText {
      width: 100%;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      border: 1px solid #d2d2de;
      border-radius: 500px;
      height: calc(100%);
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-family: "Poppins";
        font-size: 18px;
      }
    }
  }
  .Allproject_project_icon:hover {
    border: 1px solid transparent;
    overflow: visible;

    .Allproject_project_iconText {
      opacity: 1;
      width: 250px;
      visibility: visible;
      position: absolute;
      border: 1.5px solid $blue;
      border-radius: 500px;
      height: calc(100% + 2px);
      background-color: $blue;
      left: 0;
      top: -1px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        color: white;
      }
    }
  }
}

.Allproject_action_column {
  height: calc(100% - 2px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  p {
    flex: 0 0 calc(100% - 40px);
    max-width: calc(100% - 40px);
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    font-size: calc(9px + 0.45vw);
    font-weight: 400;
  }
}
// ACTIONS

.IMGmodifier {
  height: 27px;
  margin-right: 10px;
  margin-bottom: 5px;
}

//nombre de projets

.project_number {
  p {
    width: 100%;
    text-align: right;
    padding-right: 5px !important;
    color: #707070;
    font-family: "worksans";
    font-weight: 400;
  }
}

// colone personnalisés pour le tableau

.col1demi {
  flex: 0 0 13.666667%;
  max-width: 13.666667%;
}
.col1quart {
  flex: 0 0 11.333333%;
  max-width: 11.333333%;
}
.checkmarkzone {
  width: 33px !important;
}
.Allproject_project_user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 15px !important;
  p {
    color: var(--B_W);
    margin: 0;
  }
  [type="checkbox"]:not(:checked) {
    background-color: initial;
  }
  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    left: 0;
    position: relative;
    cursor: pointer;
  }
  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    border-radius: 2px;
    outline: none;
    border: 1px solid #767676;
    transition: 0.3s;
    width: 18px;
    height: 18px;
    margin: 0;
    outline: none;
  }
  [type="checkbox"]:checked {
    background-image: url("../../images/icon/check.svg");
    background-size: 70%;
    -webkit-filter: invert(0.9);
    filter: invert(0.9);
    background-repeat: no-repeat;
    background-position: 50%;
    border: 2px solid #727272;
  }
  .compte {
    background-color: var(--blue);
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    p {
      margin: 0;
      padding: 0;
      margin-bottom: 0;
      color: white;
      font-size: var(--veryverylittlefont);
    }
  }
  .width100 {
    width: 100%;
  }
}
.Allproject_project_text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 70px);
  p {
    color: var(--B_W);
    margin: 0;
    text-align: left;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0px;
    padding-right: 4px;
    font-size: var(--verylittlefont);
    line-height: 118%;
  }

  .width100 {
    width: 100%;
  }
  .email {
    opacity: 0.6;
    font-size: var(--veryveryverylittlefont);
    font-weight: 300;
  }
}

.Allproject_project_name {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .acces {
    margin: 0;
    padding: 0;
  }
  input {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
}
.morebtn {
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  border-left: none !important;
  width: 25px;
  height: 100%;
  overflow: visible;
  .moreimg {
    position: relative;
    width: 24px;
    height: 24px;
    height: auto;
    overflow: visible;
    .image {
      filter: var(--invertbis);
      opacity: 0.3;
      width: 24px;
      cursor: pointer;
    }
  }
  .btnonecontent {
    // padding-left: 24px;
    // padding-right: 24px;
    margin-left: 5px;

    display: flex;
    align-items: center;
    position: relative;

    opacity: 1;
    overflow: visible;

    img {
      filter: var(--invert2);
    }
    svg {
      fill: var(--B_W);
      width: 17px;
      height: 17px;
      opacity: 0.5;
      cursor: pointer;
    }
    svg:hover {
      opacity: 1;
    }
    svg:active {
      opacity: 0.9;
      //  background-color: rgba(40, 50, 233, 0.2);
    }
  }
}
.adduserbtn {
  display: flex;
  justify-content: center;
  align-content: center;
  width: calc(100% - 30px);
  left: 15px;
  position: absolute;
  bottom: 15px;
  margin: auto;
  border: 1px solid var(--separateur_horizontal);
  height: 35px;
  align-items: center;
  cursor: pointer;
  svg {
    width: 20px;
    margin-right: 10px;
  }

  p {
    font-size: var(--veryverylittlefont);
    margin: 0;
    display: flex;
    align-items: center;
    height: auto;
    user-select: none;
  }
}
.adduserbtn:hover {
  border-color: var(--bluehover);
}
.adduserbtn:active {
  border-color: var(--blueactive);
}
.adduser {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 20px;
  position: absolute;
  margin-top: 1.7vh;

  button {
    opacity: 0.8;
    margin-right: 15px;
    border: none;
    border-radius: 2px;
    background-color: #4149f6;
    justify-content: center;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    p {
      margin: 0;
      color: white;
      justify-content: center;
      display: flex;
      width: 100%;
      font-weight: 400;
      font-family: "worksans";
      font-size: var(--verylittlefont);
    }
  }
  button:hover {
    opacity: 1;
  }
}
.addMultipleUser {
  display: flex;
  justify-content: flex-end;
  width: auto;
  margin-right: 20px;
  position: absolute;
  margin-top: 1.7vh;
  right: 250px;
  button {
    opacity: 0.8;
    margin-right: 15px;
    border: none;
    border-radius: 2px;
    background-color: #4149f6;
    justify-content: center;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    p {
      margin: 0;
      color: white;
      justify-content: center;
      display: flex;
      width: 100%;
      font-weight: 400;
      font-family: "worksans";
      font-size: var(--verylittlefont);
    }
  }
  button:hover {
    opacity: 1;
  }
}

.faildata {
  width: 100%;
  height: 40px;
  div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 17px;
    border-top: 1px solid rgba(255, 255, 255, 0.048);
    img {
      height: 25px;
      filter: invert(1);
    }
    p {
      width: 100%;
      text-align: center;
      margin-top: 7px;
      font-size: var(--verylittlefont);
      font-weight: 400;
    }
  }
}
.choosemachinezone {
  height: 100%;
  padding-left: 5px !important;
  padding-right: 0px !important;
}

// GRAPH

.ht1 {
  height: 28%;
  min-height: 220px;
}
.padding {
  padding: 5px 5px;
}
.float_left {
  float: left;
}
.cursorpointer {
  cursor: pointer;
}
.no_blue:hover {
  color: white;
}

.tile {
  position: relative;

  overflow: hidden;
  display: flex;
  justify-content: center;
  vertical-align: center;
  width: 100%;
  height: 100%;
  background-color: var(--tile-bckg);
  border-radius: 4px;
  border: 0.5px solid var(--tile-border);
  box-shadow: 5px 6px 14px var(--tile-box-shadow);
  // border: 1px solid var(--tile-box-border);

  .topbar {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 34px;
    background-color: var(--topbar);
    z-index: 2;
    padding: 0 15px;
    border-bottom: 1px solid var(--borderbottom);
    p {
      font-size: var(--verylittlefont);
      font-weight: 400;
      color: var(--B_W);
    }

    @media (max-width: 1140px) {
      padding: 0 10px;
    }

    img {
      opacity: 1;
      // height:35px;
      height: 56%;
      margin: 0;
      filter: var(--invert);
    }
    svg {
      opacity: 1;
      // height:35px;
      height: 18px;
      margin: 0;
      filter: var(--invert);
    }
    .extopen {
      display: flex;
      align-items: center;
      opacity: 0.8;

      // height:35px;
      height: 100%;
      margin: 0;
      img {
        height: 50%;

        @media (max-width: 1140px) {
          height: 60%;
        }
      }
    }
  }

  .topbarnobkg {
    background-color: transparent !important;
    border-bottom: none !important;
  }

  .topbardecouvrir {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    z-index: 2;
    padding: 0 16px;
    padding-top: 16px;
    padding-right: 20px;

    p {
      font-size: calc(var(--veryveryverylittlefont));

      font-family: "montserrat";
      font-weight: 500 !important;
      color: var(--B_W);
    }

    @media (max-width: 1140px) {
      padding: 0 10px;
    }

    img {
      opacity: 1;
      // height:35px;
      height: 40px;
      margin-right: 15px !important;
      margin: 0;
    }
    svg {
      opacity: 1;
      // height:35px;
      height: 15px;
      margin: 0;
    }
    .extopen {
      display: flex;
      align-items: center;
      opacity: 0.8;

      // height:35px;
      height: 100%;
      margin: 0;
      img {
        height: 50%;

        @media (max-width: 1140px) {
          height: 60%;
        }
      }
    }
  }
  .contentdecouvrir {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 16px;
    height: 100%;
    padding-top: 70px;
    padding-bottom: 70px;

    p {
      font-size: var(--medfont);
      font-family: "montserrat";
      font-weight: 500 !important;
      width: 100%;
      line-height: var(--veryverybigfont);
      color: white;
    }
    img {
      width: 62%;
      margin-top: 0;
      height: auto;
      max-height: calc(100% - 55px);
    }
    @media (max-width: 1600px) {
      padding-top: 48px;
      padding-bottom: 58px;
      align-items: center;
      p {
        width: 60%;
      }
      img {
        width: auto;
        height: calc(8vw + 10%);
      }
    }
    @media (max-width: 991px) {
      padding-top: 48px;
      p {
        width: 50%;
      }
      img {
        width: auto;
        height: calc(18vw + 10%);
      }
    }
  }
  .contentdecouvrirpack {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    height: 100%;
    padding-top: 70px;
    padding-bottom: 70px;
    div {
      .contentdecouvrirpacklogo {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 calc(16px + 17%);
        width: 100%;
        height: fit-content;
        div {
          font-size: var(--bigfont);
          color: var(--B_W);
          font-family: "montserrat";
          font-weight: 500 !important;
        }

        .onelogo {
          width: auto;
          display: flex;
          font-weight: 600 !important;
          justify-content: center;
          flex-wrap: wrap;
          font-family: "montserrat";
          font-size: var(--medfont);
          p {
            font-size: var(--medfont);
            color: var(--B_W);
            font-family: "montserrat";
            font-weight: 500 !important;
            width: 100%;
            margin-top: 10px;
            text-align: center;
            line-height: var(--veryverybigfont);
          }
          img {
            width: calc(22px + 3vw);
            margin-top: 0;
            height: auto;
          }
        }
      }
      .textzone {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 calc(16px + 15%);
        width: 100%;
        height: fit-content;
        margin-top: 40px;
        p {
          text-align: center;
          font-size: var(--verylittlefont);
          color: var(--B_W);
          font-family: "montserrat";
          font-weight: 400 !important;
          line-height: 30px;
        }
      }
    }
  }
  .botbar {
    position: absolute;
    bottom: 0;
    height: 15%;
    background-color: var(--bottom-bar);
    z-index: 2;
    padding: 0;
    display: flex;
    padding: 0 16px;

    align-items: center;
    justify-content: center;

    @media (max-width: 1140px) {
      padding: 0 10px;
      font-size: var(--veryverylittlefont);
    }
    img {
      opacity: 1;
      width: 20px;
      margin: 0;
    }
    p {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: var(--verylittlefont);
      margin: 0;
      padding: 0;
      font-weight: 500;
    }
  }

  .botbardecouvrir {
    position: absolute;
    bottom: 15px;

    padding: 0;
    display: flex;
    padding: 0 16px;

    align-items: center;
    width: 100%;
    justify-content: space-between;
    .btn {
      bottom: 0 !important;
    }
    @media (max-width: 1140px) {
      padding: 0 10px;
      font-size: var(--veryverylittlefont);
    }
    img {
      opacity: 1;
      width: 20px;
      margin: 0;
    }
    p {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: var(--verylittlefont);
      margin: 0;
      padding: 0;
      color: var(--B_W);
      font-weight: 500;
    }
  }

  img {
    opacity: 1;
    display: flex;
    padding: 0;
    margin-top: 5%;
    height: 99%;
  }
  .light {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;

    z-index: 6;
    box-shadow: inset 3px 3px 5px rgba(250, 250, 250, 0.005);
  }
  .btnplay {
    width: 50px;
    height: 50px;
    img {
      width: 100%;
    }
  }
}
.logotext {
  font-size: var(--veryverylittlefont);
  font-weight: 400 !important;
  color: var(--B_W);
  margin: 0;
}
.text_icon {
  font-weight: 400 !important;
  opacity: 0.9;
  img {
    width: 13px;
  }
  .cloud {
    width: 19px;
  }
}
.DojaCat {
  filter: var(--remy);
}

.app_metrics {
  margin-top: 34px;
  height: calc(100% - 34px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .app_metricsimg {
    height: calc(100% - 20px);
    margin-bottom: 20px;
  }
}

.onecontentzoneoee {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
  position: relative;

  .dashtrs {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 13px;
    path {
      stroke: transparent;
    }
    .doublegraph {
      padding-bottom: 0 !important;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      align-content: center;
      .linedashzone {
        position: relative !important;
        margin-bottom: 10px;
        margin-top: 10px;
        bottom: auto !important;
        width: 100%;
        .titleline {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          padding-left: 15px;
          padding-right: 15px;
          padding-bottom: 8px;
          p {
            font-size: var(--verylittlefont);
            font-weight: 400;
            color: var(--B_W);

            margin: 0;
            padding: 0;
          }
        }
      }
    }
    .numberzone {
      margin: 0;
      padding: 0;
      width: 100%;
      padding-bottom: 52px;
      .number {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 12px;
        padding-right: 12px;
        width: 100%;

        .bigfont {
          font-size: var(--veryverybigfont);
          font-weight: 400;
          color: var(--B_W);

          margin: 0;
          padding: 0;
          font-weight: 500;
          letter-spacing: 3px;
        }
        .littlefont {
          font-size: var(--bigfont);
          font-weight: 400;
          color: var(--B_W);

          margin: 0;
          padding: 0;

          margin-top: 10px;
        }
      }
      .compteutilisateur {
        font-size: var(--veryverylittlefont);
        font-weight: 300;
        color: var(--B_W);
        margin: 0;
        padding: 0;
        padding-left: 12px;
        padding-right: 12px;
      }
    }
    .titlecontentzoneimportant {
      position: absolute;
      font-size: var(--verylittlefont);
      font-weight: 400;
      color: var(--B_W);

      margin: 0;
      padding: 0;
    }
    .hoursvalue {
      position: absolute;
      bottom: 15px;
      right: 15px;
      width: fit-content;

      perspective-origin: right;
      p {
        position: relative;
        font-size: var(--veryverylittlefont);
        opacity: 0.6;
        color: var(--B_W);
      }
    }
  }
}
.linedashzone {
  bottom: 15px;
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0;

  .linedash {
    left: 15px;
    width: calc(100% - 30px);
    height: 4px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 100px;
    margin: auto;
    .value {
      height: 100%;
      width: 17%;
      border-bottom-left-radius: 100px;
      border-top-left-radius: 100px;
    }
    .bluevalue {
      background-color: #4149f6;
    }
    .greenvalue {
      background-color: #15df82;
    }
    .background {
      height: 100%;
      width: calc(100% - 17%);
      background-color: var(--dashboard_bckg);
      border-bottom-right-radius: 100px;
      border-top-right-radius: 100px;
    }
  }
  .linefont {
    margin: 0;
    padding: 0;
    opacity: 0.6;
    color: var(--B_W);
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 6px;
    font-size: var(--veryverylittlefont);
    .numberline {
      margin-right: 10px;
      font-size: var(--bigfont);
    }
    .opactity06 {
      opacity: 0.6;
    }
  }
  .noopacity {
    opacity: 1;
    display: flex;
    align-items: center;
    margin-top: 0;
  }
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: black;
  color: white;
  font-size: var(--veryverylittlefont);
  position: absolute;
  padding: 2px 10px 2px 10px;
  bottom: -18px;
  right: 20px;
  white-space: nowrap;
  border-radius: 6px;

  opacity: 0;
  border: none;
  z-index: 10;
  visibility: hidden;
  font-family: "work sans";
  font-weight: 400;
}
[data-title] {
  position: relative;
}
.selected {
  border: 1px solid white;
  &:hover {
    border: 1px solid white !important;
  }
}
.hovermember:hover {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
