@import "./homepage.module.scss";
//Choose equipment

.container {
  width: 100%;
  height: 100%;

  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 50px;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--bkgconnect);
}

.chooseequipment {
  padding: 5px !important;

  // height: calc(100% - 70px);
  // @media (min-height: 820px) {
  //   height: 695px;
  // }
  // width: calc(30% - 20px);
  display: flex;
  flex-wrap: wrap;
  float: left;
  .titlezone {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15px;

    h2 {
      margin: 0;
      font-family: "montserrat";
      font-size: var(--littlefont);
      text-transform: uppercase;
      text-align: center;
      width: 100%;
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: var(--menuconnectaccount);
    }
  }
  .containerzone {
    width: calc(100%);
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;

    padding-bottom: 15px;
    height: 100%;
    //  height: calc(calc(100% - 50px) + 3vh);
    background-color: var(--menuconnectaccount);
    .equipmenttitle {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      align-items: center;
      height: 34px;
      p {
        margin: 0;
        height: 100%;
        font-family: "worksans";
        font-weight: 400;
        font-size: var(--verylittlefont);

        max-width: calc(100% - 55px);
        text-overflow: ellipsis;
        overflow: hidden;
        display: flex;
        align-items: center;
      }
      .equipmentaction {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        height: 100%;
        .oneaction {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          align-items: center;
          width: 35px;
          svg {
            width: 24px;
            height: auto;
            opacity: 0.8;
            cursor: pointer;
            .fill {
              fill: var(--B_W) !important;
            }
            .strokenone {
              stroke: none !important;
            }
          }
          svg:hover {
            opacity: 1;
          }
        }
      }
    }
    .allequipments {
      width: 100%;
      height: calc(100% - 150px);
      border-radius: 2px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      overflow: scroll;
      padding-top: 2px;
      padding-bottom: 2px;
      border: 1px solid var(--borderinput);
      align-content: flex-start;
      .oneequipment {
        height: min-content;
        padding-top: 15px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: calc(100% - 30px);
        border-radius: 2px;
        opacity: 0.8;
        cursor: pointer;
        margin-top: 7px;
        margin-bottom: 7px;
        border: 1px solid var(--borderequipment);
        img {
          margin: auto;
          height: auto;
          width: 55%;
          filter: var(--invert);
          opacity: 0.9;
        }
        p {
          margin: 0;
          margin-top: 7px;
          display: block;
          text-align: center;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: "montserrat";
          white-space: nowrap;
          font-size: var(--veryveryverylittlefont);
        }
      }
      .newequipment {
        svg {
          width: 25px;
          margin-right: 15px;
        }
        p {
          width: auto !important;
          margin-top: 0;
        }
      }
      .selected {
        opacity: 1;
        border: 1px solid var(--B_W) !important;
        background-color: var(--selectequipment);
        img {
          filter: invert(0) !important;
          opacity: 1 !important;
        }
        p {
          color: white !important;
        }
      }
      .oneequipment:hover {
        opacity: 1;
        transition: 0.3s;
      }
    }
    .equipmentfinalize {
      width: 100%;
      height: calc(100px);
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        margin: 0;
        font-family: "montserrat";
        font-size: var(--veryverylittlefont);
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 400;
        line-height: 180%;
        opacity: 0.6;
        svg {
          width: 20px;
          margin-left: 2px;
          margin-right: 2px;
          path {
            fill: var(--B_W);
          }
        }
      }
    }
  }
}
