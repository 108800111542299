.container {
  flex-wrap: wrap;
  overflow: hidden;
  background-color: var(--bkgconnect);
  height: calc(100% - 70px);
  align-content: flex-start;
  width: calc(100%);

  .float_left {
    float: left;
  }

  p {
    margin: 0;
    padding: 0;
    font-family: "worksans";
  }
  span {
    color: var(--select) !important;
  }

  //  margin-left: 16vw;
  padding: 0 30px;
  overflow: scroll;
  padding-bottom: 30px;

  // @media (max-width: 1250px) {
  //   margin-left: 200px;
  //   width: calc(100vw - 200px);
  // }

  // @media (min-width: 1552px) {
  //   margin-left: 240px;
  //   width: calc(100vw - 240px) !important;
  // }

  .welcom {
    width: 100%;
    height: 60px;
    background-color: var(--welcom);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--medfont);
    font-weight: 450;
    margin: 12px 0px 16px 0px;
    p {
      text-align: center;
      margin: 0;
    }
    span {
      color: #849bff;
    }
  }

  .sectioncolor {
    opacity: 0.4;

    height: 24px;
    position: absolute;
    top: 70px;
    left: -40px;
    padding: 0;
    width: calc(100% + 80px);
  }

  .bluesectioncolor {
    background-color: #1794cd;
  }
  .orangesectioncolor {
    background-color: #fbba00;
  }
  .greensectioncolor {
    background-color: #98c226;
  }
  .sectioncolor {
    opacity: 0.4;
  }
  .sectiontitlezone {
    // border-bottom: 1px solid rgba(255, 255, 255, 0.082);
    padding: 0;
    padding-bottom: 0px !important;
    padding-top: 10px;
    width: 100%;

    // height: 40px;

    p {
      font-size: var(--verylittlefont);
      font-weight: 500;
      color: var(--B_W);
      padding: 0;
      margin: 0;
      padding-bottom: 0px;
      padding-top: 13px;
    }
  }
  .padding {
    padding: 5px 5px;
  }

  .align {
    align-items: center;
  }

  .tile {
    position: relative;
    margin-top: 12px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    vertical-align: center;
    width: 100%;
    height: 100%;
    background-color: var(--tile-bckg);
    border-radius: 10px;
    border: 0.5px solid var(--tile-border);
    box-shadow: 5px 6px 14px var(--tile-box-shadow);
    // border: 1px solid var(--tile-box-border);

    .topbar {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 34px;
      background-color: var(--topbar);
      z-index: 2;
      padding: 0 16px;
      border-bottom: 1px solid var(--borderbottom);
      p {
        font-size: var(--verylittlefont);
        font-weight: 400;
        color: var(--B_W);
      }

      @media (max-width: 1140px) {
        padding: 0 10px;
      }

      img {
        opacity: 1;
        // height:35px;
        height: 56%;
        margin: 0;
        filter: var(--invert);
      }
      svg {
        opacity: 1;
        // height:35px;
        height: 18px;
        margin: 0;
        filter: var(--invert);
      }
      .extopen {
        display: flex;
        align-items: center;
        opacity: 0.8;

        // height:35px;
        height: 100%;
        margin: 0;
        img {
          height: 50%;

          @media (max-width: 1140px) {
            height: 60%;
          }
        }
      }
    }

    .topbarnobkg {
      background-color: transparent !important;
      border-bottom: none !important;
    }

    .topbardecouvrir {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      z-index: 2;
      padding: 0 16px;
      padding-top: 16px;
      padding-right: 20px;

      p {
        font-size: calc(var(--veryveryverylittlefont));

        font-family: "montserrat";
        font-weight: 500 !important;
        color: var(--B_W);
      }

      @media (max-width: 1140px) {
        padding: 0 10px;
      }

      img {
        opacity: 1;
        // height:35px;
        height: 40px;
        margin-right: 15px !important;
        margin: 0;
      }
      svg {
        opacity: 1;
        // height:35px;
        height: 15px;
        margin: 0;
      }
      .extopen {
        display: flex;
        align-items: center;
        opacity: 0.8;

        // height:35px;
        height: 100%;
        margin: 0;
        img {
          height: 50%;

          @media (max-width: 1140px) {
            height: 60%;
          }
        }
      }
    }
    .contentdecouvrir {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 0 16px;
      height: 100%;
      padding-top: 70px;
      padding-bottom: 70px;

      p {
        font-size: var(--medfont);
        font-family: "montserrat";
        font-weight: 500 !important;
        width: 100%;
        line-height: var(--veryverybigfont);
        color: white;
      }
      img {
        width: 62%;
        margin-top: 0;
        height: auto;
        max-height: calc(100% - 55px);
      }
      @media (max-width: 1600px) {
        padding-top: 48px;
        padding-bottom: 58px;
        align-items: center;
        p {
          width: 60%;
        }
        img {
          width: auto;
          height: calc(8vw + 10%);
        }
      }
      @media (max-width: 991px) {
        padding-top: 48px;
        p {
          width: 50%;
        }
        img {
          width: auto;
          height: calc(18vw + 10%);
        }
      }
    }
    .contentdecouvrirpack {
      width: 100%;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      height: 100%;
      padding-top: 70px;
      padding-bottom: 70px;
      div {
        .contentdecouvrirpacklogo {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          padding: 0 calc(16px + 17%);
          @media (max-width: 720px) {
            padding: 0 0;
          }
          width: 100%;
          height: fit-content;
          div {
            font-size: var(--bigfont);
            color: var(--B_W);
            font-family: "montserrat";
            font-weight: 500 !important;
          }

          .onelogo {
            width: auto;
            display: flex;
            font-weight: 600 !important;
            justify-content: center;
            flex-wrap: wrap;
            font-family: "montserrat";
            font-size: var(--medfont);
            p {
              font-size: var(--medfont);
              color: var(--B_W);
              font-family: "montserrat";
              font-weight: 500 !important;
              width: 100%;
              margin-top: 10px;
              text-align: center;
              line-height: var(--veryverybigfont);
            }
            img {
              width: calc(22px + 3vw);
              margin-top: 0;
              height: auto;
            }
          }
        }
        .textzone {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          padding: 0 calc(16px + 15%);
          width: 100%;
          height: fit-content;
          margin-top: 40px;
          p {
            text-align: center;
            font-size: var(--verylittlefont);
            color: var(--B_W);
            font-family: "montserrat";
            font-weight: 400 !important;
            line-height: 30px;
          }
        }
      }
    }
    .botbar {
      position: absolute;
      bottom: 0;
      height: 15%;
      background-color: var(--bottom-bar);
      z-index: 2;
      padding: 0;
      display: flex;
      padding: 0 16px;

      align-items: center;
      justify-content: center;

      @media (max-width: 1140px) {
        padding: 0 10px;
        font-size: var(--veryverylittlefont);
      }
      img {
        opacity: 1;
        width: 20px;
        margin: 0;
      }
      p {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: var(--verylittlefont);
        margin: 0;
        padding: 0;
        font-weight: 500;
      }
    }

    .botbardecouvrir {
      position: absolute;
      bottom: 15px;

      padding: 0;
      display: flex;
      padding: 0 16px;

      align-items: center;
      width: 100%;
      justify-content: space-between;
      .btn {
        bottom: 0 !important;
      }
      @media (max-width: 1140px) {
        padding: 0 10px;
        font-size: var(--veryverylittlefont);
      }
      img {
        opacity: 1;
        width: 20px;
        margin: 0;
      }
      p {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: var(--verylittlefont);
        margin: 0;
        padding: 0;
        color: var(--B_W);
        font-weight: 500;
      }
    }
    .bottbarbluecolor{
    
      width: 100%;
      .btngroup{
        width: 100%;
        background-color: #044B94;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        .btn{
          border: none !important;
          width: 100%;
          display: flex;
          justify-content: space-between;
    
        }
      }
    }
    .bottbarmaxwidth{
    
      width: 100%;
      .btngroup{
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        flex-wrap: nowrap;
        p{
          width: auto;
          font-family: "worksans";
          font-weight: 500;
          font-size: var(--veryverylittlefont) !important;
        }
        .btn{
      
          
         
      
    
        }
      }
    }

    img {
      opacity: 1;
      display: flex;
      padding: 0;
      margin-top: 5%;
      height: 99%;
    }
    .light {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;

      z-index: 6;
      box-shadow: inset 3px 3px 5px rgba(250, 250, 250, 0.005);
    }
    .btnplay {
      width: 50px;
      height: 50px;
      img {
        width: 100%;
      }
    }
  }

  .ht0 {
    height: 14%;
    min-height: 110px;
  }
  .httools {
    height: 22%;
    min-height: 180px;
  }
  .ht1 {
    height: 28%;
    min-height: 220px;
  }
  .ht2 {
    height: 55.99%;
    min-height: 440px;
  }

  .ht3 {
    height: 84%;
    min-height: 600px;
  }


  .ht0sm {
    @media (max-width: 992px) {
      height: 14%;
      min-height: 110px;
    }
  }
  .ht0sm {
    @media (max-width: 720px) {
      height: 14%;
      min-height: 152px;
    }
  }
  .httoolssm {
    @media (max-width: 1600px) {
      height: 22%;
      min-height: 180px;
    }
  }
  .ht1sm {
    @media (max-width: 1600px) {
      height: 28%;
      min-height: 220px;
    }
  }

  .ht2sm {
    @media (max-width: 1600px) {
      height: 56%;
      min-height: 440px;
    }
  }

  .ht3sm {
    @media (max-width: 1600px) {
      height: 84%;
      min-height: 600px;
    }
  }

  .light:hover {
    background-color: var(--lightselect);
    transition: 0.1s;
  }
  .cursorpointer {
    cursor: pointer;
  }

  .btn {
    display: flex;
    justify-content: center;
    vertical-align: center;
    position: absolute;
    height: 30px;
    width: auto;
    background-color: none;
    bottom: 15px;
    right: 16px;
    border: 1px solid var(--borderbtn);
    background-color: var(--with);
    color: var(--B_W);
    border-radius: 4px;
    align-items: center;
    @media (max-width: 992px) {
      height: 26px;
      bottom: 5%;
      right: 8px;
    }

    p {
      display: flex;
      align-items: center;
      text-align: center;
      padding: 2px 10px;
      font-size: var(--veryverylittlefont);
    }
  }

  .btn:hover {
    background-color: #ffffff17;
    cursor: pointer;
  }
  .btn:active {
    background-color: #ffffff10;
    cursor: pointer;
  }

  .btngroup {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .marginright {
      margin-right: 10px;
    }
    .marginleft {
      margin-left: 10px;
    }
  }
  .positionrelativebtn {
    position: relative;
    right: 0;
    padding: 2px 10px;
    p {
      font-size: var(--veryverylittlefont) !important;
    }

    z-index: 10;
  }
.harrisbrunoright{
  right: 16px;
  position: absolute;
}
  .app {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      // width: 60%;
      height: 47%;
      margin: 0;
    }
  }
  .app_metrics {
    margin-top: 34px;
    height: calc(100% - 34px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .app_metricsimg {
      height: calc(100% - 20px);
      margin-bottom: 20px;
    }
  }
  .imgbkg {
    margin-top: 0;
    height: calc(100%);
    width: 100%;
    .app_metricsimg {
      object-fit: cover;
      margin-bottom: 0;
      margin-top: 0px;
      height: -webkit-fill-available;
      width: inherit;
    }
  }

  .content {
    position: absolute;
    top: 34px;
    padding: 0 16px;
    margin-top: 3%;
    p {
      font-size: var(--verylittlefont);
      line-height: 160%;
      font-weight: 400;
    }
    @media (max-width: 992px) {
      margin-top: 1%;
      line-height: 140%;
    }
    @media (max-width: 1140px) {
      padding: 0 10px;
    }
  }
}
.onecontentzone {
  width: 20%;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
  position: relative;
  .titlecontentzone {
    margin: 0;
    padding: 0;
    font-size: var(--verylittlefont);
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: flex-end;
    width: 100%;
    height: 30px;

    position: absolute;
    bottom: 10px;
  }

  .dashtrs {
    height: calc(100% - 30px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    path {
      stroke: transparent;
    }
    p {
      position: absolute;
      font-size: var(--verylittlefont);
      font-weight: 400;
      color: var(--B_W);

      margin: 0;
      padding: 0;
    }

    .hoursvalue {
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: fit-content;

      perspective-origin: right;
      p {
        position: relative;
        color: var(--B_W);
      }
    }
  }
}
.onecontentzoneoee {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
  position: relative;

  .dashtrs {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 13px;
    path {
      stroke: transparent;
    }
    .doublegraph {
      padding-bottom: 0 !important;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      align-content: center;
      .linedashzone {
        position: relative !important;
        margin-bottom: 10px;
        margin-top: 10px;
        bottom: auto !important;
        .titleline {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          padding-left: 15px;
          padding-right: 15px;
          padding-bottom: 8px;
          p {
            font-size: var(--verylittlefont);
            font-weight: 400;
            color: var(--B_W);

            margin: 0;
            padding: 0;
          }
        }
      }
    }
    .numberzone {
      margin: 0;
      padding: 0;
      width: 100%;
      padding-bottom: 52px;
      .number {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 12px;
        padding-right: 12px;
        width: 100%;

        .bigfont {
          font-size: var(--veryverybigfont);
          font-weight: 400;
          color: var(--B_W);

          margin: 0;
          padding: 0;
          font-weight: 500;
          letter-spacing: 3px;
        }
        .littlefont {
          font-size: var(--bigfont);
          font-weight: 400;
          color: var(--B_W);

          margin: 0;
          padding: 0;

          margin-top: 10px;
        }
      }
      .compteutilisateur {
        font-size: var(--veryverylittlefont);
        font-weight: 300;
        color: var(--B_W);
        margin: 0;
        padding: 0;
        padding-left: 12px;
        padding-right: 12px;
      }
    }
    .titlecontentzoneimportant {
      position: absolute;
      font-size: var(--verylittlefont);
      font-weight: 400;
      color: var(--B_W);

      margin: 0;
      padding: 0;
    }
    .hoursvalue {
      position: absolute;
      bottom: 15px;
      right: 15px;
      width: fit-content;

      perspective-origin: right;
      p {
        position: relative;
        font-size: var(--veryverylittlefont);
        opacity: 0.6;
        color: var(--B_W);
      }
    }
  }
}
.rounddash {
  svg {
    height: 100%;

    rect {
      //     fill: var(--colorgraph);
      fill: transparent;
    }
  }
}
.linedashzone {
  bottom: 15px;
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0;

  .linedash {
    left: 15px;
    width: calc(100% - 30px);
    height: 4px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 100px;
    margin: auto;
    .value {
      height: 100%;
      width: 17%;
      border-bottom-left-radius: 100px;
      border-top-left-radius: 100px;
    }
    .bluevalue {
      background-color: #4149f6;
    }
    .greenvalue {
      background-color: #15df82;
    }
    .background {
      height: 100%;
      width: calc(100% - 17%);
      background-color: var(--dashboard_bckg);
      border-bottom-right-radius: 100px;
      border-top-right-radius: 100px;
    }
  }
  .linefont {
    margin: 0;
    padding: 0;
    opacity: 0.6;
    color: var(--B_W);
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 6px;
    font-size: var(--veryverylittlefont);
  }
}
.text_icon {
  font-weight: 400 !important;
  opacity: 0.9;
  img {
    width: 13px;
  }
  .cloud {
    width: 19px;
  }
}
.verylittlefonts {
  font-size: var(--veryverylittlefont) !important;
}

.virtualbook {
  height: 100%;
  width: 100%;
  padding: 5px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.heightbottom {
  height: 34px;

  bottom: 0;
  position: absolute !important;
  right: 0;
  padding-top: 2px !important;
  padding-bottom: 0 !important;
  width: 100%;
  padding-right: 35px !important;
  display: flex;
  align-items: flex-start;
  background-color: var(--bkgconnect);
  z-index: 100;
  justify-content: flex-end;
  a {
    font-size: calc(var(--veryveryverylittlefont));
    opacity: 0.6;
  }
}
.no_blue:hover {
  color: white;
}

.DojaCat {
  filter: var(--remy);
}

.topproj {
  height: calc(100% - 34px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-top: 34px;
  flex-wrap: wrap;
  .oneproj {
    width: 100%;
    height: 33.333%;
    border-bottom: 1px solid #3e3e3e;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    padding-left: 20px;
    padding-right: 15px;

    .text {
      width: 83%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      padding: 0;
      .titleproj {
        justify-content: space-between;
        align-items: center;
        font-size: var(--verylittlefont);
        font-weight: 400;
        color: var(--B_W);
        margin: 0;
        padding: 0;
        font-family: "worksans";
        font-family: "worksans";
        width: calc(100% - 85px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .visites {
        font-size: var(--veryveryverylittlefont);
        font-weight: 400;
        color: var(--B_W);
        margin: 0;
        padding: 0;
        font-family: "worksans";
        width: 85px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .numberproj {
      width: 17%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      p {
        font-size: var(--verylittlefont);
        font-weight: 400;
        color: var(--blue);
        margin: 0;
        padding: 0;
        font-family: "worksans";
        border: 0.5px solid var(--blue);
        border-radius: 100px;
        width: var(--verybigfont);
        height: var(--verybigfont);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .oneproj:nth-child(3) {
    border-bottom: none;
  }
}
.logotext {
  font-size: var(--veryverylittlefont);
  font-weight: 500 !important;
  color: var(--B_W);
}
.bluebackground {
  background: linear-gradient(#3899c8, #2e7dad, #205f95) !important;
}
.blueimgbackground {
  background-color: transparent !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../images/screenbkg.png");
  image-rendering: auto;
}
.oragnebackground {
  background: linear-gradient(#fbba25, #eb6400) !important;
}
.orangeimgbackground {
  background-color: transparent !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../images/screenbkgdash.png");
  image-rendering: auto;
}
.greybackground {
  // background: linear-gradient(#313131, #000000) !important
  background-color: black;
}
.greenimgbackground {
  background-color: transparent !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../images/screenbkgjet.png");
  image-rendering: auto;
}
.registerbackground {
  background-color: transparent !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../images/screenregister.svg");
  image-rendering: auto;
}

.shopfoilbackground {
  background-color: transparent !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../images/screenshopfoil.png");
  image-rendering: auto;
}

.textaligncenter {
  text-transform: uppercase;
  margin-left: calc(0.2vw + 44px) !important;
  //  font-size: calc(var(--verylittlefont) - 2px) !important;
}

.ht0 {
  height: 14%;
  min-height: 110px;
}
.httools {
  height: 22%;
  min-height: 180px;
}
.ht1 {
  height: 28%;
  min-height: 220px;
}
.ht2 {
  height: 55.99%;
  min-height: 440px;
}

.ht3 {
  height: 84%;
  min-height: 650px;
}

.ht0sm {
  @media (max-width: 992px) {
    height: 14%;
    min-height: 110px;
  }
}
.httoolssm {
  @media (max-width: 1600px) {
    height: 22%;
    min-height: 180px;
  }
}
.ht1sm {
  @media (max-width: 1600px) {
    height: 28%;
    min-height: 220px;
  }
}

.ht2sm {
  @media (max-width: 1600px) {
    height: 56%;
    min-height: 440px;
  }
}

.ht3sm {
  @media (max-width: 1600px) {
    height: 84%;
    min-height: 600px;
  }
}
.bkgblue{
  background-color: #00366C !important;
  p{
    color:white !important;
  }
  svg{
    filter: invert(0) !important;
  }
  .btn{
    border: 1px solid #e6e6e680 !important;
  }
}





.borderbottombkgheighcolor{
  border-bottom: 1px solid rgba(168, 168, 168, 0.168) !important;
}