.zonemodal {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bkgconnect);
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 600px;
  height: 200px;
  background-color: var(--tile-bckg);
  position: absolute;
  border-radius: 8px;
  margin: auto;

  .bat_modals_title {
    margin-top: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    h5 {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
    }
  }
  @media (min-width: 1600px) {
    .bat_modals_titlecontent {
      margin-bottom: 15px !important;
      margin-top: 0px !important;
    }
  }

  .bat_modal_margin2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto !important;
    .bat_modals_title {
      margin-top: 0px !important;
      display: flex;
      justify-content: center;
      align-items: center;

      h5 {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .bat_modals_import {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
