.boxwelcom {
  padding: 0px 6px;
  // padding: 0px 5px;
}
.welcom {
  padding: 0 30px !important;
  .primary_btn {
    display: flex;
    font-weight: 550;
    font-size: var(--veryverylittlefont);
    justify-content: center;
    align-items: center;
    height: 30px;
    background-color: #4149f6;
    width: 120px;
    border-radius: 1px;
    cursor: pointer;
  }

  width: 100%;
  height: 40px;
  // background-color: var(--welcom);

  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--littlefont);
  font-weight: 450;
  margin: 0px 0px 0px 0px;
  border-bottom: solid 0.5px var(--tile-border);
  padding: 0px 0px;
  p {
    text-align: center;
    margin: 0;
  }
  span {
    color: #849bff;
  }
}








.container {
  width: 100%;
  max-width: 1350px;
  margin: auto;
  flex-wrap: wrap;
  overflow: scroll;
  background-color: var(--bkgconnect);
  height: calc(100vh - 104px);
  // width: 84vw;
  align-content: flex-start;
  justify-content: center;


  .float_left {
    float: left;
  }

  p {
    margin: 0;
    padding: 0;
  }
  span {
    color: var(--select) !important;
  }

  // margin-left: 16vw;
  padding: 0 30px;
  padding-bottom: 70px;
  @media (max-width: 1380px) {
    max-width: 1050px;
  }

  @media (min-width: 1552px) {
    // margin-left: 240px;
    // width: calc(100vw - 240px) !important;
  }

  .padding {
    padding: 5px 5px;
  }

  .align {
    align-items: center;
  }

  .tile {
    position: relative;
    margin-top: 12px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    vertical-align: center;
    width: 100%;
    height: 100%;
    background-color: var(--tile-bckg);
    border-radius: 4px;
    border: 0.5px solid var(--tile-borderlanding);
    box-shadow: 5px 6px 14px var(--tile-box-shadow);
   // border: 1px solid var(--tile-box-border);

    .topbar {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 64px;
    //  background-color: var(--topbar);
      z-index: 2;
      padding: 0 16px;
      .invert{
        filter: var(--invert2);
      }
    //  border-bottom: 1px solid var(--borderbottom);
      p {
        font-size: var(--medfont);
        font-weight: 500;
        color: var(--B_W);
      }
      .approvecolor {
        color: var(--blueapprove2);
      }
      .dashboardcolor {
        color: var(--dashboardcolor);
      }

      @media (max-width: 1140px) {
        padding: 0 10px;
      }

      img {
        opacity: 1;
        // height:35px;
        height: 56%;
        margin: 0;
        filter: var(--invert);
      }
      svg {
        opacity: 1;
        // height:35px;
        height: 18px;
        margin: 0;
        filter: var(--invert);
        
      }
      svg {
        width: 24px;
        height: auto;
      }
      .extopen {
        display: flex;
        align-items: center;
        opacity: 0.8;

        // height:35px;
        height: 100%;
        margin: 0;
        img {
          height: 50%;

          @media (max-width: 1140px) {
            height: 60%;
          }
        }
      }
    }
    .topbarnobkg{
      background-color: transparent !important;
      border-bottom: none !important;
    }
    .topbardecouvrir {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      left: 0;

      z-index: 2;
      padding: 0 16px;
      padding-top: 16px;
      padding-right: 20px;
      .bkgtextzone{
        height: 38px;
        background-color: #ffffff17;
        position: absolute;
        left: 0%;
        width: 340px;
        display: flex;
        align-items: center;
        p {
          font-size: calc(var(--bigfont));
  
          font-family: "montserrat";
          font-weight: 500 !important;
          color: white ;
          margin-left: 90px;
        }
      }
      p {
        font-size: calc(var(--veryveryverylittlefont));

        font-family: "montserrat";
        font-weight: 500 !important;
        color: white ;
      }

      // @media (max-width: 1140px) {
      //   padding: 0 10px;
      // }

      img {
        opacity: 1;
        // height:35px;
        height: 54px;
        margin-right: 15px !important;
        margin: 0;
      }
      svg {
        opacity: 1;
        // height:35px;
        height: 15px;
        margin: 0;
      }
      .extopen {
        display: flex;
        align-items: center;
        opacity: 0.8;

        // height:35px;
        height: 100%;
        margin: 0;
        img {
          height: 50%;

          @media (max-width: 1140px) {
            height: 60%;
          }
        }
      }
    }
    .contentdecouvrir {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 16px;
      height: 100%;
      padding-top: 90px;
      padding-bottom: 70px;

      p {
        font-size: var(--medfont);
        font-family: "montserrat";
        font-weight: 500 !important;
        width: 100%;
        line-height: var(--veryverybigfont);
        color: white ;
      }
      img {
        width: auto;
         height: 87%;
       
        
        position: absolute;
        bottom: 10px;
        right: 10%;
      }
      @media (max-width: 1600px) {
        padding-top: 78px;
        padding-bottom: 58px;
        // align-items: center;
        p {
          width: 60%;
        }
        img {
          width: auto;
          // height: calc(8vw + 10%);
        
          height: 87%;
          position: absolute;
          bottom: 10px;
          right: 10%;
        }
      }
      // @media (max-width: 991px) {
      //   padding-top: 48px;
      //   p {
      //     width: 50%;
      //   }
      //   img {
      //     width: auto;
      //     height: calc(18vw + 10%);
      //   }
      // }
    }



    .botbardecouvrir {
      position: absolute;
      bottom: 15px;
      

      padding: 0;
      display: flex;
      padding: 0 16px;

      align-items: center;
      width: 100%;
      justify-content: space-between;
      .btn{
        bottom: 0 !important;
      }
      @media (max-width: 1140px) {
        padding: 0 10px;
        font-size: var(--medfont) !important;
      }
      img {
        opacity: 1;
        width: 20px;
        margin: 0;
      }
      p {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: var(--verylittlefont);
        margin: 0;
        padding: 0;
        color: white !important;
        font-weight: 400;
        font-family: "montserrat";
      }
    }

    .botbar {
      position: absolute;
      bottom: 0;
      height: 15%;
    //  background-color: var(--bottom-bar);
      // z-index: 2;
      padding: 0;
      display: flex;
      padding: 0 16px;

      align-items: center;
      justify-content: center;

      @media (max-width: 1140px) {
        padding: 0 10px;
        font-size: var(--veryverylittlefont);
      }
      img {
        opacity: 1;
        width: 20px;
        margin: 0;
      }
      p {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: var(--verylittlefont);
        margin: 0;
        padding: 0;
        font-weight: 500;
      }
    }
    .discover {
      width: 100% !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    img {
      opacity: 1;
      display: flex;
      padding: 0;
      margin-top: 5%;
      height: 99%;
    }
    .fullscreenimg{
      opacity: 1;
      display: flex;
      padding: 0;
      margin-top: 0%;
      height: 100%;
    }
    .light {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;

      z-index: 6;
      box-shadow: inset 3px 3px 5px rgba(250, 250, 250, 0.005);
    }
    .btnplay {
      width: 50px;
      height: 50px;
      img {
        width: 100%;
      }
    }
  }

  .ht0 {
    height: 14%;
    min-height: 110px;
  }
  .httools {
    height: 22%;
    min-height: 180px;
  }
  .ht1 {
    height: 28%;
    min-height: 220px;
  }
  .ht15 {
    height: 35%;
    min-height: 280px;
  }
  .ht2 {
    height: 55.99%;
    min-height: 440px;
  }

  .ht3 {
    height: 84%;
    min-height: 600px;
  }

  .ht0sm {
    @media (max-width: 992px) {
      height: 14%;
      min-height: 110px;
    }
  }
  .httoolssm {
    @media (max-width: 1600px) {
      height: 22%;
      min-height: 180px;
    }
  }
  .ht1sm {
    @media (max-width: 1600px) {
      height: 28%;
      min-height: 220px;
    }
  }
  .ht115sm {
    @media (max-width: 1600px) {
      height: 35%;
      min-height: 280px;
    }
  }

  .ht2sm {
    @media (max-width: 1600px) {
      height: 56%;
      min-height: 440px;
    }
  }

  .ht3sm {
    @media (max-width: 1600px) {
      height: 84%;
      min-height: 600px;
    }
  }

  .light:hover {
    background-color: var(--lightselect);
    transition: 0.1s;
  }
  .cursorpointer {
    cursor: pointer;
  }

  .btn {
    display: flex;
    justify-content: center;
    vertical-align: center;
    position: absolute;
    height: 30px;
    width: auto;
    background-color: none;
    bottom: 15px;
    right: 16px;
    border: 1px solid var(--borderbtn);
    background-color: var(--with);
    color: var(--B_W);
    border-radius: 4px;
    align-items: center;

    @media (max-width: 992px) {
      height: 26px;
      bottom: 5%;
      right: 8px;
    }

    p {
      display: flex;
      align-items: center;
      text-align: center;
      padding: 2px 10px;
      font-size: var(--veryverylittlefont);
    }
  }

  .btn:hover {
    background-color: #ffffff17;
    cursor: pointer;
  }
  .btn:active {
    background-color: #ffffff10;
    cursor: pointer;
  }
  .btngroup{
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .marginright{
      margin-right: 10px ;
    }
    .marginleft{
      margin-left: 10px ;
    }

  }
  .positionrelativebtn {
    position: relative;
    right: 0;
    padding: 17px 25px;
    p {
      font-weight: 400;
      
    //  font-size: var(--veryverylittlefont) !important;
    }

    z-index: 10;
  }
  .app {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      width: 60%;
      height: 60%;
      margin: 0;
    }
  }
  .app_metrics {
    margin-top: 34px;
    height: calc(100% - 34px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .app_metricsimg {
      height: calc(100% - 20px);
      margin-bottom: 20px;
    }
  }
  .imgbkg{
    margin-top: 0;
    height: calc(100%);
    width: 100%;
    .app_metricsimg {


      object-fit: cover;
      margin-bottom: 0;
      margin-top: 0px;
      height: -webkit-fill-available;
      width: inherit;
    }
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
  }
  .iconzone {
    top: 0 !important;
    margin-top: 0 !important;
    height: 100%;
    padding: 0px 16px !important;
  }
  .content {
    position: absolute;
    top: 0;
    padding: 0 16px;
    padding-top: 65px;
    margin-top: 0%;
 
  
    height: 100%;
    .img3Delement{
      height: 75%;
      width: auto;
    }
    .svginvertcolor{
      height: 80%;
      g{
        g{
          filter: var(--invert2);
          g{
            filter: invert(0);
          }
        }

        .Rectangle{
          filter: invert(0);
        }
      }
    }
    .content_icon {
      justify-content: center;
      // width: 100%;
      // height: 100%;
      // max-width: 320px;
      width: 200px;
      height: 200px;
      margin: auto;
      max-width: 200px;
      max-height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      ul {
        // padding: 0 16px;
        padding: 0;
        list-style: none;
        margin: 0;
        width: 100%;
        height: 100%;
        li {
          padding: 0px 1px;
          display: flex;
          height: 66px;
          justify-content: space-between;
          img {
          }
        }
      }
    }

    ul {
      padding: 6% 16px;
      list-style: none;

      margin: 0;
      li {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
        p {
          width: fit-content;
          width: calc(100% - 24px);
        }
      }
    }
    p {
      font-size: calc(var(--verylittlefont) + 0.1rem);
      line-height: 220%;
      font-weight: 400;
    }
    @media (max-width: 992px) {
      margin-top: 1%;
      line-height: 140%;
    }
    @media (max-width: 1140px) {
      padding: 0 10px;
    }
  }
  .notopbar {
    top: 0;
    padding-top: 0px;
    margin-top: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.list {
  width: 100%;
  height: 200px;
  padding: 0 !important;
  margin-top: 5px;

  ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0px !important;
    margin: 0;
    li {
      align-items: center;
      align-content: center;
      height: 42px;
      display: flex;

      width: 100%;
      img {
        display: flex;
        margin: 0 !important;
        padding: 0;
      }
      p {
        margin: 0px 0px 1px 20px;
        width: fit-content !important;
        height: 20px;
        display: flex;
        padding: 0;
        line-height: 0;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
      }
    }
  }
}
.line_icon {
  margin-top: 3%;
  padding: 20% 0%;
  margin: 0;
  display: flex;
  justify-content: space-between;
  background-color: #15df8100;
  width: 100%;
  height: 120px;
  .line_box {
    padding: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    .line_intern_box {
      padding: 0;
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 26px;
        padding: 8px 0px;
      }
      p {
        white-space: pre;
        font-size: var(--veryveryverylittlefont);
        width: 100%;
        text-align: center;
      }
    }
  }
}

.onecontentzone {
  width: 20%;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
  position: relative;
  .titlecontentzone {
    margin: 0;
    padding: 0;
    font-size: var(--verylittlefont);
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: flex-end;
    width: 100%;
    height: 30px;

    position: absolute;
    bottom: 10px;
  }

  .dashtrs {
    height: calc(100% - 30px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    path {
      stroke: transparent;
    }
    p {
      position: absolute;
      font-size: var(--verylittlefont);
      font-weight: 400;
      color: var(--B_W);

      margin: 0;
      padding: 0;
    }

    .hoursvalue {
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: fit-content;

      perspective-origin: right;
      p {
        position: relative;
        color: var(--B_W);
      }
    }
  }
}
.onecontentzoneoee {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
  position: relative;

  .dashtrs {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 13px;
    path {
      stroke: transparent;
    }
    .doublegraph {
      padding-bottom: 0 !important;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      align-content: center;
      .linedashzone {
        position: relative !important;
        margin-bottom: 10px;
        margin-top: 10px;
        bottom: auto !important;
        .titleline {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          padding-left: 15px;
          padding-right: 15px;
          padding-bottom: 8px;
          p {
            font-size: var(--verylittlefont);
            font-weight: 400;
            color: var(--B_W);

            margin: 0;
            padding: 0;
          }
        }
      }
    }
    .numberzone {
      margin: 0;
      padding: 0;
      width: 100%;
      padding-bottom: 52px;
      .number {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 12px;
        padding-right: 12px;
        width: 100%;

        .bigfont {
          font-size: var(--veryverybigfont);
          font-weight: 400;
          color: var(--B_W);

          margin: 0;
          padding: 0;
          font-weight: 500;
          letter-spacing: 3px;
        }
        .littlefont {
          font-size: var(--bigfont);
          font-weight: 400;
          color: var(--B_W);

          margin: 0;
          padding: 0;

          margin-top: 10px;
        }
      }
      .compteutilisateur {
        font-size: var(--veryverylittlefont);
        font-weight: 300;
        color: var(--B_W);
        margin: 0;
        padding: 0;
        padding-left: 12px;
        padding-right: 12px;
      }
    }
    .titlecontentzoneimportant {
      position: absolute;
      font-size: var(--verylittlefont);
      font-weight: 400;
      color: var(--B_W);

      margin: 0;
      padding: 0;
    }
    .hoursvalue {
      position: absolute;
      bottom: 15px;
      right: 15px;
      width: fit-content;

      perspective-origin: right;
      p {
        position: relative;
        font-size: var(--veryverylittlefont);
        opacity: 0.6;
        color: var(--B_W);
      }
    }
  }
}
.rounddash {
  svg {
    height: 100%;

    rect {
      //     fill: var(--colorgraph);
      fill: transparent;
    }
  }
}
.linedashzone {
  bottom: 15px;
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0;

  .linedash {
    left: 15px;
    width: calc(100% - 30px);
    height: 4px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 100px;
    margin: auto;
    .value {
      height: 100%;
      width: 17%;
      border-bottom-left-radius: 100px;
      border-top-left-radius: 100px;
    }
    .bluevalue {
      background-color: #4149f6;
    }
    .greenvalue {
      background-color: #15df82;
    }
    .background {
      height: 100%;
      width: calc(100% - 17%);
      background-color: var(--dashboard_bckg);
      border-bottom-right-radius: 100px;
      border-top-right-radius: 100px;
    }
  }
  .linefont {
    margin: 0;
    padding: 0;
    opacity: 0.6;
    color: var(--B_W);
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 6px;
    font-size: var(--veryverylittlefont);
  }
}
.groupicon{
  display: flex;
  flex-wrap: wrap;
  img {
    height: 18px !important;
    width: auto !important;
    margin-left: 20px !important;
  }
}
.text_icon {
  font-weight: 400 !important;
  opacity: 0.9;

  img {
    height: 18px;
   
  }
  .cloud {
    width: 19px;
  }
  .icon_3D {
    padding-top: 10px !important;
    width: 26px;
    @media (max-width: 992px) {
      padding-top: 6px !important;
      width: 24px;
    }
  }
  .icon_PDF {
    padding-top: 10px !important;
    width: 26px;
    @media (max-width: 992px) {
      padding-top: 6px !important;
      width: 24px;
    }
  }
  .icon_puce {
    width: 20px !important;
    margin: 0px 12px 0px -12px;
  }
  .icon_protec {
    padding-top: 10px !important;
    width: 26px;
    @media (max-width: 992px) {
      padding-top: 6px !important;
      width: 24px;
    }
  }
  .icon_dashboard {
    padding-top: 10px !important;
    width: 26px;
    @media (max-width: 992px) {
      padding-top: 6px !important;
      width: 24px;
    }
  }
  .icon_intuitif {
    padding-top: 10px !important;
    width: 26px;
    @media (max-width: 992px) {
      padding-top: 6px !important;
      width: 24px;
    }
  }
  .icon_puce {
    width: 28px;
  }
  .style_icon {
    padding-top: 16px !important;
    width: 28px;
    @media (max-width: 992px) {
      padding-top: 16px !important;
      width: 26px;
    }
  }
  
}
.verylittlefonts {
  font-size: var(--veryveryverylittlefont) !important;
}

.virtualbook {
  height: 100%;
  width: 100%;
  padding: 5px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.heightbottom {
  height: 28px;
}
.no_blue:hover {
  color: white;
}

.DojaCat {
  filter: var(--remy);
}
.logotext {
  font-size: var(--veryverylittlefont);
  font-weight: 500 !important;
  color: var(--B_W);
}
.bluebackground {
  background: linear-gradient(175deg, rgba(56,153,200,0.8) 0%, rgba(46,125,173,0.8) 15%, rgba(32,95,149,0.8) 100%) !important;
}
.imgbackgroundblue {
  background-color: #597FA6 !important;
}
.imgbackgroundwhite {
  background-color: #ffffff !important;
}
.blueimgbackground {
  background-color: transparent !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../images/screenbkg.png");
  image-rendering: auto;
}
.oragnebackground {
  background: linear-gradient(#fbba25, #eb6400) !important;
}
.orangeimgbackground {
  background-color: transparent !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../images/screenbkgdash.png");
  image-rendering: auto;
}
.greybackground {
 // background: linear-gradient(#313131, #000000) !important
background-color: black;
}
.greenimgbackground {
  background-color: transparent !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../images/screenbkgjet.png");
  image-rendering: auto;
}
.textaligncenter{
  text-transform: uppercase;
  margin-left: calc(0.2vw + 44px) !important;
//  font-size: calc(var(--verylittlefont) - 2px) !important;
}
.blendmodeluminosity{
  mix-blend-mode: luminosity;
}
.zone2D3D{
  border: 1px solid var(--borderbtn);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 10;
  border-radius: 3px;

  div{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 38px;
    cursor: pointer;
    font-size: var(--veryverylittlefont);
    font-weight: 400 !important;
    color: var(--B_W);
    font-family: 'Poppins';
  
  }
  .cursor{
    position: absolute;
    right: 1px;
    background-color: var(--blueapprove2);
    z-index: -1;
    width: 36px;
    height: 26px;
    border-radius: 3px;
  }
}
.buttonautresproj{
  border: 1px solid var(--borderbtn);
  padding: 0px 15px;
  height: 32px;
  display: flex;
  align-items: center;
  z-index: 10;
  cursor: pointer;
  p{
    font-size: var(--veryverylittlefont) !important;
    font-weight: 400 !important;
    color: var(--B_W);
    font-family: 'Poppins';
  }

}
.buttonautresproj:hover{
  background-color: #ffffff17;
  p{
    font-size: var(--veryverylittlefont) !important;
    font-weight: 400 !important;
    color: var(--B_W);
    font-family: 'Poppins';
    margin: 0;
  }

}