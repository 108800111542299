:root {
  --checkinvert: invert(1);
  --primary_modal: #0e1014;
  --selected: #768fff;
  --colortext: #ffffff;
  --colortext2: #000000;
  --colortext_opacity: #ffffff50;
  --border_modal: #3b3b3b83;

  --bkgconnect: #1d2023;
  --bkgdisabled: #1d2023;
  --menuconnect: #16181d;

  --borderbottom: rgba(94, 94, 94, 0.26);

  --invert: invert(0);
  //  --colorprinc: #141214;
  // --colordate: #0A090A;
  // --colorsec:#0B0A0C;

  // --colortopgraph: #17171B;
  // --colorgraph:#1A191E;
  --bckg: #1b1d21;

  --colordate: #212024;
  // --colorsec:#27262b;

  --colorsec: #1e1f21;

  --colortopgraph: #2d2d33;
  --colorgraph: #323338;

  --toptable: #101010;
  --table2: #141414;

  --table2: #101010;
  --table1: #323436;
  --blue1: #18dfff;
  --blue2: #3b90ff;

  --blueapprove: #4149f6;
  --blueapprove2: #4c80ab;
  --dashboardcolor: #e6915d;
  --bluetext: #7690ff;
  --bluehover: #3c5ceb;
  --blueactive: #063a90;

  --backgroundmodal: #1f2023;
  --navleft: #0e1014;

  // homepage
  --welcom: #00000010;
  --topbar: rgb(15, 15, 15);
  --bottom-bar: rgb(10, 10, 10);

  --tile-bckg: rgba(0, 0, 0, 0.5);
  // --tile-border: rgba(0, 0, 0, 0.303);
  --tile-border: #4040401c;
  --tile-borderlanding: #4040402d;
  --tile-box-shadow: rgba(0, 0, 0, 0.15);

  --lightselect: rgba(255, 255, 255, 0.02);

  --dashboard_bckg: #232427;
  --B_W: #ffffff;
  --W_B: #000000;
  --whith: #3b3b3b10;

  --select: #849bff;
  --hbcolor: #FF68B4;
  --backg_transparent: #00000060;
  --Title2: #ffffff50;
  --choosefile_border: #ffffff60;

  // WorkTeam

  --separateur: rgba(255, 255, 255, 0.062);
  --separateur_horizontal: #404040;
  --bar_top: #505050;
  --yellow_admin: #ffffc7;
  --bckg_scrollbar: #ffffff10;
  --scrollbar: #ffffff80;

  --borderbtn: #e6e6e680;
  @media (max-width: 1500px) {
    //fonts size
    --veryverybigfont: calc(29px + 6 * ((100vw - 320px) / 680)); //20
    --verybigfont: calc(13px + 6 * ((100vw - 320px) / 680)); //18
    --bigfont: calc(12px + 6 * ((100vw - 320px) / 680)); //17
    --regularfont: calc(11px + 4 * ((100vw - 320px) / 680)); //15
    --medfont: calc(14px + 3 * ((100vw - 320px) / 680)); //15 + 018
    --semilittlefont: calc(14px + 0.18vw); //14 + 018
    --littlefont: calc(13px + 3 * ((100vw - 320px) / 680)); //12 +O.18
    --verylittlefont: calc(9px + 4 * ((100vw - 320px) / 680)); //11
    --veryverylittlefont: calc(9px + 3 * ((100vw - 320px) / 680)); //11
    --veryveryverylittlefont: calc(8px + 3 * ((100vw - 320px) / 680)); //11
    --veryverylittlefonttable: calc(10px + 3 * ((100vw - 340px) / 680)); //11
  }

  @media (max-width: 576px) {
    //fonts size
    --veryverybigfont: calc(29px + 6 * ((100vw - 320px) / 680)); //20
    --verybigfont: calc(13px + 6 * ((100vw - 320px) / 680)); //18
    --bigfont: calc(12px + 6 * ((100vw - 320px) / 680)); //17
    --regularfont: calc(11px + 4 * ((100vw - 320px) / 680)); //15
    --medfont: calc(15px + 0.18vw); //15 + 018
    --semilittlefont: calc(14px + 0.18vw); //14 + 018
    --littlefont: 14px; //12 +O.18
    --verylittlefont: 13px; //11
    --veryverylittlefont: 12px; //11
    --veryveryverylittlefont: 11px; //11
    --veryverylittlefonttable: 10px; //11
  }
  //fonts size

  //fonts size
  --veryverybigfont: calc(29px + 6 * ((100vw - 320px) / 680)); //20
  --verybigfont: calc(13px + 6 * ((100vw - 320px) / 680)); //18
  --bigfont: calc(12px + 6 * ((100vw - 320px) / 680)); //17
  --regularfont: calc(14px + 4 * ((100vw - 320px) / 680)); //15
  --medfont: calc(13px + 4 * ((100vw - 320px) / 680)); //15 + 018
  --semilittlefont: calc(12px + 4 * ((100vw - 320px) / 680)); //14 + 018
  --littlefont: calc(12px + 4 * ((100vw - 320px) / 680)); //12
  --verylittlefont: calc(9px + 4 * ((100vw - 320px) / 680)); //11
  --veryverylittlefont: calc(8px + 3 * ((100vw - 320px) / 680)); //11
  --veryveryverylittlefont: calc(7px + 3 * ((100vw - 320px) / 680)); //11
  --veryverylittlefonttable: calc(10px + 3 * ((100vw - 320px) / 680)); //11

  //connection
  --backgroundaccount: #1f2022;
  --menuconnectaccountborder: #46464671;
  --menuconnectaccount: #0f1011;
  --checkboxaccount: #1d1e1f;
  --buttonaccount: #064bbb;
  --buttonaccounthover: #063a90;
  --redcolor: #fc7b7b;
  --borderhover: #3060bc;

  --tile-box-border: #ffffff3b;
  --toolsbkg: #343434;
}
// Tablette --blue1: #386EFF;

$principal: #f2f2f2;
$bkgbutton: #eeeff6;
$bkg: #eeeff6;
$blue: #4149f6;
$blue_active: linear-gradient(to right, #2931df, #161ed4);
$red: #fd7480;
$green: #2cb08a;
$notactivate: #d5d5db;

//TEXTE
$texte: #646464;

//ESPACE NAVBAR
$navspace: 80px;

//FONTS SIZE
//@import "./base/rfs.scss";

//FONTS SIZE

$font9: calc(11px + 0.3vw);
$font6_2: calc(8px + 0.2vw);
$font6_3: calc(8px + 0.4vw);
$font7_25: calc(9px + 0.25vw);
$font8_3: calc(10px + 0.3vw);

$font8_5: calc(10px + 0.5vw);

$font9_3: calc(11px + 0.3vw);
$font9_4: calc(11px + 0.3vw);
$font10_3: calc(12px + 0.3vw);
$font10_4: calc(13px + 0.5vw);
$font10_5: calc(12px + 0.5vw);
$font10_6: calc(12px + 0.6vw);
$font11_3: calc(13px + 0.3vw);

$font12_3: calc(14px + 0.3vw);
$font18_4: calc(18px + 0.4vw);

//TEXTE
$texte: #646464;
$textenotactive: #c7c7cd;

[data-theme="light"] {
  --primary_modal: #f3f3f3;
  --selected: #5b78f8;
  --colortext: #000000dc;
  --colortext2: #ffffff;
  --colortext_opacity: #00000050;
  --border_modal: #a1a1a183;

  --bkgconnect: #f9f9fa;
  --bkgdisabled: #eeeeee;
  --menuconnect: #16181d;
  --invert: invert(1);
  //  --colorprinc: #141214;
  // --colordate: #0A090A;
  // --colorsec:#0B0A0C;

  // --colortopgraph: #17171B;
  // --colorgraph:#1A191E;
  --bckg: #edf0f5;

  --colordate: #dfd9ee;
  // --colorsec:#27262b;

  --colorsec: #dce2ec;

  --colortopgraph: #d7d7ee;
  --colorgraph: #e2e5f1;

  --toptable: #f7f7f7;
  --table2: #f0f0f0;

  --table1: #323436;
  --blue1: #18dfff;
  --blue2: #3b90ff;

  --blueapprove: #4149f6;
  --blueapprove2: #4c80ab;
  --bluetext: #7690ff;
  --bluehover: #3c5ceb;
  --blueactive: #506ef7;

  --backgroundmodal: #e0e5f5;
  --navleft: #c1d1f1;

  // homepage
  --checkinvert: invert(0);
  --welcom: #d8d8d832;

  --menuconnect: #16181d;
  --topbar: #ffffff;
  --bottom-bar: #f3f3f3;

  --tile-bckg: rgb(255, 255, 255);
  --tile-border: rgba(189, 189, 189, 0.26);

  --tile-box-shadow: rgba(0, 0, 0, 0.015);

  --lightselect: rgba(0, 0, 0, 0.03);

  --dashboard_bckg: #cecece;
  --B_W: #000000dc;
  --W_B: #ffffff;
  --whith: #3b3b3b00;
  --remy: invert(1);

  --select: #355aff;
  --hbcolor: #FF68B4;
  --backg_transparent: #00000000;
  --Title2: #0000004d;
  --choosefile_border: #0000001a;

  // WorkTeam

  --separateur: #00000010;
  --separateur_horizontal: #00000010;
  --bar_top: #00000010;
  --yellow_admin: #fa983c;
  --bckg_scrollbar: #00000010;
  --scrollbar: #00000021;

  // 06.28 Colors_Refresh
  --blue-approve: #004bbb;
  --orange-dashboard: #fbba50;

  //Default
  --bckg: #f5f5f760;
  --tiled: #ffffff;

  //DarkMod
  --bckg: #1f202290;
  --tiled: #0e0f10;

  //connection
  --backgroundaccount: #f9f9fa;

  --borderbtn: #3b3b3b80;
  --tile-box-border: #4e4e4e18;
  --borderbottom: rgb(245, 245, 245);

  --supportborder: #16181d;
  --menuconnectaccountborder: #7070705d;
  --menuconnectaccount: #ffffff;
  --checkboxaccount: #ffffff;
  --borderinput: #aaaaaa;
  --opacityaccount: 0.9;

  --displaynonew: none;
  --displaynoneb: block;
  --bkgtab: #f9f9fa;
  --bkgtabhead: #f9f9fa;
  --bkgtabbody: #f9f9ff;
  --bkgtabbodyhover: #e3eefd;
  --yellow: #ad640b;

  --borderequipment: #a3a3a3;

  --owned: #d7e8fb;
}

[data-theme="dark"] {
  --borderequipment: #606060;
  --yellow: #fffcb5;
  --borderbtn: #e6e6e680;

  --borderinput: #aaaaaa00;

  --bkgtab: #36373e;
  --bkgtabhead: #1f2124;
  --bkgtabbody: #121417;
  --bkgtabbodyhover: #222c40;

  --toolsbkg: #343434;

  --opacityaccount: 0.6;
  --checkinvert: invert(1);
  --primary_modal: #0e1014;
  --selected: #768fff;
  --colortext: #ffffff;
  --colortext2: #000000;
  --colortext_opacity: #ffffff50;
  --border_modal: #3b3b3b83;

  --bkgconnect: #1d2023;
  --bkgdisabled: #1d2023;
  --menuconnect: #16181d;

  --borderbottom: rgba(94, 94, 94, 0.26);

  --invert: invert(0);
  --invert2: invert(1);
  //  --colorprinc: #141214;
  // --colordate: #0A090A;
  // --colorsec:#0B0A0C;

  // --colortopgraph: #17171B;
  // --colorgraph:#1A191E;
  --bckg: #1b1d21;

  --colordate: #212024;
  // --colorsec:#27262b;

  --colorsec: #1e1f21;

  --colortopgraph: #2d2d33;
  --colorgraph: #323338;

  --toptable: #101010;
  --table2: #141414;

  --table2: #101010;
  --table1: #323436;
  --blue1: #18dfff;
  --blue2: #3b90ff;

  --blueapprove: #4149f6;
  --blueapprove2: #4c80ab;

  --dashboardcolor: #e6915d;
  --bluetext: #7690ff;
  --bluehover: #3c5ceb;
  --blueactive: #506ef7;

  --backgroundmodal: #1f2023;
  --navleft: #0e1014;

  // homepage
  --welcom: #00000010;
  --topbar: rgb(15, 15, 15);
  --bottom-bar: rgb(10, 10, 10);

  --tile-bckg: rgba(0, 0, 0, 0.5);
  // --tile-border: rgba(0, 0, 0, 0.303);
  --tile-border: #4040401c;
  --tile-borderlanding: #4040405d;
  --tile-box-shadow: rgba(0, 0, 0, 0.15);

  --lightselect: rgba(255, 255, 255, 0.02);

  --dashboard_bckg: #232427;
  --B_W: #ffffff;
  --W_B: #000000;
  --whith: #3b3b3b10;

  --select: #849bff;
  --hbcolor: #FF68B4;
  --backg_transparent: #00000060;
  --Title2: #ffffff50;
  --choosefile_border: #ffffff60;

  // WorkTeam

  --separateur: rgba(255, 255, 255, 0.062);
  --separateur_horizontal: #404040;
  --bar_top: #505050;
  --yellow_admin: #ffffc7;
  --bckg_scrollbar: #ffffff10;
  --scrollbar: #ffffff80;

  --borderbtn: #e6e6e680;

  //connection
  --backgroundaccount: #1f2022;
  --menuconnectaccountborder: #46464671;
  --menuconnectaccount: #0f1011;
  --checkboxaccount: #1d1e1f;
  --buttonaccount: #064bbb;
  --buttonaccounthover: #063a90;
  --redcolor: #fc7b7b;
  --borderhover: #3060bc;

  --tile-box-border: #ffffff3b;
  --toolsbkg: #343434;

  --supportborder: rgba(255, 255, 255, 0.179);
  --displaynonew: block;
  --displaynoneb: none;

  --owned: #142d47;
}
