.Container {
  flex-wrap: wrap;
  overflow: hidden;
  background-color: var(--bkgconnect);
  height: calc(100% - 75px);
  align-content: flex-start;
  justify-content: space-between;
  width: calc(100%);
  display: flex;
  padding-top: 15px !important;

  .float_left {
    float: left;
  }

  p {
    margin: 0;
    padding: 0;
    font-family: "worksans";
    font-size: var(--verylittlefont);
  }

  //  margin-left: 16vw;
  padding: 0 30px;

  padding-bottom: 30px;
}
.notimportant {
  opacity: 0.6;
  font-weight: 300;
}
.owned {
  background-color: var(--owned) !important;
}
.selected {
  border: 2px solid var(--borderhover) !important;
}
.importanttext {
  color: var(--blue) !important;
}
.chooseextension {
  width: 68%;
  height: 100%;
  display: flex;
  overflow: scroll;
  align-content: flex-start;

  justify-content: space-between;
  flex-wrap: wrap;

  .packselection {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    height: 65%;
    max-height: calc(10vw + 500px);

    .onepack {
      transition: 0.3s;
      background-color: var(--W_B);
      border: 2px solid transparent;
      padding: 20px;
      width: 30%;
      height: 100%;
      border-radius: 4px;
      position: relative;
      .title {
        font-size: var(--medfont);
        margin-bottom: 5px;
      }
      .logopack {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 15px;
        img {
          width: 20%;
          margin-right: 5%;
        }
      }
    }

    .price {
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 110%;
      font-size: var(--verybigfont);
      .priceabo {
        font-size: var(--medfont);
      }
    }
    .contacteznous {
      margin-top: 25px;
      width: 100%;
      height: 35px;
      cursor: pointer;
      display: flex;
      align-items: center;
      user-select: none;
      justify-content: center;
      border: 1px solid var(--borderbtn);
    }
    .contacteznous:hover {
      border: 2px solid var(--borderhover);
    }
    .textecontacteznous {
      padding-top: 25px;
      p {
        text-align: center;
      }
    }
    .oneoptiondetail {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .abonnementactif {
      position: absolute;
      bottom: -35px;
      width: 100%;
      display: flex;
      justify-content: center;
      left: 0;
    }
  }

  .optionselection {
    margin-top: 70px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .oneoptionselection {
      width: fit-content;
      padding-bottom: 20px;

      p {
        white-space: nowrap;
        font-size: var(--verylittlefont);
      }
      .choosenumberzone {
        display: flex;
        flex-wrap: wrap;
        padding-top: 15px;
        .onenumber {
          border: 1px solid var(--borderbtn);
          width: 40px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
          cursor: pointer;
          p {
            user-select: none;
            margin: 0;
            padding: 0;
            font-size: var(--veryverylittlefont);
          }
        }
        .onenumber:hover {
          border: 2px solid var(--bluehover);
        }
        .onenumber:active {
          transition: 0s;
          border: 2px solid var(--blueactive) !important;
          //   box-shadow: 0px 0px 10px var(--bluehover);
        }
        .onenumber:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.pricedetail {
  width: 29%;
  position: relative;

  height: 100%;
  border: 1px solid var(--borderbtn);

  .pricedetailzone {
    overflow: scroll;
    height: calc(100% - 125px);
    width: 100%;
    @media (max-width: 1042px) {
      height: calc(100% - 135px);
    }
  }
  .titleabo {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid var(--borderbtn);

    p {
      font-size: var(--medfont);
    }
  }

  .packzone {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid var(--borderbtn);

    .packzonetitle {
      font-size: var(--medfont);
      padding-bottom: 10px !important;
    }
    .oneoptiondetail {
      padding-bottom: 5px;
      padding-top: 5px;
      font-size: var(--verylittlefont);
    }
  }

  .periodezone {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid var(--borderbtn);

    .periodecompo {
      display: flex;
      justify-content: space-between;
    }
  }

  .detailzone {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;

    .onedetail {
      width: 100%;
      padding-bottom: 5px;
      padding-top: 5px;
      .pricetext {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
      }
      .pricecalc {
        width: 100%;
        font-weight: 300;
      }
    }
  }
  .btnzone {
    width: calc(100% - 40px);
    display: flex;
    justify-content: center;

    height: 100px;
    align-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    position: absolute;
    bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    @media (max-width: 1042px) {
      height: 110px;
    }
    .btncontent {
      width: calc(100%);
      display: flex;
      justify-content: center;
      background-color: var(--blueapprove);
      cursor: pointer;
      height: 40px;
      align-items: center;

      bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
      p {
        user-select: none;
        text-transform: uppercase;
        color: white;
      }
    }
    .commandertext {
      color: white;
      font-size: var(--veryverylittlefont);
      text-align: center;
    }
  }
  .btncontent:hover {
    background-color: var(--bluehover);
  }
  .btncontent:active {
    background-color: var(--blueactive);
    //   box-shadow: 0px 0px 10px var(--bluehover);
  }
}

.textcenter {
  text-align: center;
}

.contactUs {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.disabled {
  cursor: default !important;
  opacity: 0.5;
  &:active {
    opacity: 0.5;
  }
}

.loader {
  width: 30px;
  height: 30px;
  border: 3px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingDiv {
  cursor: default !important;
  &:hover {
    background-color: var(--blueapprove) !important;
  }
}
.divforrenouvelabo{
  position: relative;
  overflow: auto;
  height: calc(100% - 50px);
}
.divfornotrenouvelabo{
  position: relative;
  overflow: auto;
  height: calc(100%);
}
.btnrenouvelerabo{
  // Bouton Renouveler Abonnement a retirer et mettre un flex pour faire apparaitre a nouveau le btn
  display: none;

  justify-content: center;
  align-content: center;
  width: calc(100% );
  position: relative;
  margin: auto;
  border: 1px solid var(--separateur_horizontal);
  height: 35px;
  margin-top: 15px;
  align-items: center;
  cursor: pointer;
  opacity: 0.8;
  &:hover{
    color: var(--B_W) !important;
    opacity: 1;
  }
}
.scrollview::-webkit-scrollbar:vertical {
  display: block;
  width: 2px;
  border-radius: 20px;
}
.scrollview::-webkit-scrollbar:hover {
  display: block;
  width: 3px !important;
  border-radius: 20px;
}
.scrollview::-webkit-scrollbar-thumb {
  border-right-width: 5px;
  border-radius: 30px;
  background-color: #8f8f8f2f;
}
.scrollview::-webkit-scrollbar-thumb:hover {
  background-color: #575757;
}