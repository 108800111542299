@import "./variable.scss";
.container {
  flex-wrap: wrap;
  overflow: scroll;
  background-color: var(--bkgconnect);
  height: calc(100vh - 60px);
  // width: 84vw;
  width: 100%;
  align-content: flex-start;

  // margin-left: 16vw;
  padding: 0 30px;

  @media (max-width: 1250px) {
    // margin-left: 200px;
    // width: calc(100vw - 200px);
  }

  @media (min-width: 1552px) {
    // margin-left: 240px;
    // width: calc(100vw - 240px) !important;
  }
  div {
    margin: 0;
    padding: 0;
  }
  .titleprinc {
    padding-top: 15px;

    h2 {
      font-size: var(--verybigfont);
      font-family: "Poppins";
      font-weight: 500;
    }
  }

  .onecontent {
    padding: 0;
  }
  .firstcontent {
    padding: 0;
  }

  .title {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    p {
      margin: 0 !important;
    }
    .titletext {
      width: 100%;
      font-size: var(--medfont);
      font-weight: 500;
      font-family: "worksans";
      height: 38px;
      display: flex;
      align-items: center;
    }
    .name {
      margin-left: 20px !important;
      font-size: var(--bigfont);
      font-weight: 500;
    }
  }

  .bluetitle {
    padding-top: 30px;
    padding-bottom: 15px;
    p {
      color: var(--select);
      text-transform: uppercase;
      display: flex;
      align-items: center;
      margin: 0;
      font-size: var(--verylittlefont);
      font-weight: 500;
      font-family: "worksans";
    }
  }

  .compte {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #4149f6;
    border: solid 1px #4149f6;
    border-radius: 100%;

    height: 38px;
    width: 38px;

    .letter {
      font-size: 17px;
      font-family: "Poppins";
      font-weight: 600;
      color: white;
      margin: 0;
      padding: 0;
      letter-spacing: 1px;
    }
  }
  .comptebig {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #4149f6;
    border: solid 1px #4149f6;
    border-radius: 100%;

    height: 58px;
    width: 58px;
    cursor: pointer;
    overflow: hidden;

    .letter {
      font-size: 25px;
      font-family: "Poppins";
      font-weight: 500;
      color: white;
      margin: 0;
      padding: 0;
      letter-spacing: 1px;
    }
  }
  .comptechange {
    width: calc(100% - 100px);
    label,
    input,
    p {
      font-size: var(--veryveryverylittlefont);
      font-weight: 400;
      font-family: "worksans";
    }
    label {
      // background-color: ;
      padding-left: 7px;
      padding-right: 7px;
      padding-top: 3px;
      padding-bottom: 3px;
      border-radius: 2px;
      border: 0.5px solid var(--choosefile_border);
      cursor: pointer;
      transition: 0.1s;
      margin: 0;
      float: right;
      font-weight: 500;
    }
    label:hover {
      background-color: var(--bluehover);
      border: 1px solid var(--bluehover);

      color: white;
    }
  }
  .changeimg {
    .imgprofile {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      border-radius: 0;
      margin: 0;
      .photo {
        position: absolute;
        bottom: 0;
        border-top: none;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: 0.1s;
        img {
          filter: invert(1);
          width: 17px;
        }
      }
    }
    .imgprofile:hover {
      .photo {
        position: absolute;
        bottom: 0;
        border-top: 2px solid rgba(76, 81, 126, 0.158);
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        opacity: 0;
        background-color: rgba(46, 52, 77, 0.192);

        opacity: 0.9;

        img {
          filter: invert(1);
          width: 17px;
        }
      }
    }
  }

  .label {
    font-size: var(--veryveryverylittlefont);
    font-weight: 400;
    font-family: "worksans";
    width: 100%;
  }
  .firstcontent {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }
  .onecontent {
    display: flex;
    flex-wrap: wrap;

    padding-right: calc(15px + 1vw);
    height: 100%;
    @media (max-width:720px){
      margin-top: 0 !important;
    }

    .oneform {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: calc(0.5vh + 2px);
      padding-top: calc(0.7vh + 0px);

      label {
        text-transform: uppercase;
        font-size: var(--veryverylittlefont);
        color: var(--Title2);
        font-weight: 500;
        font-family: "worksans";
      }
      input {
        color: var(--B_W);
        background-color: var(--backg_transparent);
        border: 1px solid var(--menuconnect);
        width: 100%;
        height: 50px;
        border-radius: 3px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: var(--medfont);
        font-weight: 400;
        font-family: "worksans";
        outline: none;
        display: flex;
        align-items: center;
      }
    }
  }
  .input {
    background-color: #00000060;
    width: 100%;
    height: 36px;
    border-radius: 3px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: var(--medfont);
    font-weight: 400;
    font-family: "worksans";
    outline: none;
    display: flex;
    align-items: center;
    p {
      margin: 0;
    }
  }
  input[type="file"] {
    display: none;
  }
  .space {
    width: 100%;
    p {
      margin: 0;
      height: 40px;
    }
  }
}

.changeimg {
  margin-top: calc(2vh + 22px) !important;
  background-color: var(--backg_transparent);
  border: 1px solid var(--menuconnect);
  border-radius: 3px;
  padding-bottom: calc(1vh + 15px) !important;
  padding-top: calc(1vh + 15px) !important;
  padding-left: calc(15px + 1vw) !important;
  padding-right: calc(15px + 1vw) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.theme {
  border: #00000000 solid 0.5px;
  border-radius: 4px;
  background-color: #00000000;
  margin-top: 100px !important;
  padding-bottom: 38px !important;
  font-size: var(--littlefont) !important;
}
.display_flex {
  display: flex;
  justify-content: space-between;
}
.no_border {
  border: 1px solid var(--menuconnect) !important;
  font-size: var(--littlefont) !important;
  p {
    color: var(--B_W);
  }
}
.no_padding {
  padding: 0px !important;
  margin-top: 10% !important;
}
.vingt {
  width: 20% !important;
  text-transform: uppercase;
  color: var(--Title2);
  padding-top: 8px;
  font-weight: 500;
  p {
    font-size: var(--littlefont) !important;
    color: var(--B_W);
  }
}
.quatre_vingt {
  width: 70% !important;
  position: relative;

  background-color: var(--backg_transparent) !important;
  border: 1px solid var(--menuconnect);
  p {
    font-size: var(--littlefont) !important;
    color: var(--B_W);
  }
}

.changedarklight {
  position: absolute;
  right: 15px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  .darkbtn {
    border: 1.5px solid rgb(199, 199, 199);
    width: 17px;
    height: 17px;
    cursor: pointer;
    border-radius: 100px;
    background-color: black;
    margin-left: 4px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
  }
  .lightbtn {
    border: 1.5px solid rgb(41, 41, 41);
    width: 17px;
    height: 17px;
    cursor: pointer;
    border-radius: 100px;
    background-color: white;
    margin-left: 4px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bluebtn {
    border: 2px solid var(--bluehover);
    width: 17px;
    height: 17px;
    cursor: pointer;
    border-radius: 100px;
    background-color: var(--bluehover);
    margin-left: 4px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .selected {
    border: 2px solid var(--bluehover);
    width: 25px;
    height: 25px;
    position: absolute;
    border-radius: 200px;
  }
}

.littlething {
  color: #ffffff;
  font-weight: 400;
  font-size: var(--verylittlefont) !important;
  height: 24px;
  cursor: pointer;
}

.notClickableLittlething {
  font-weight: 400;
  color: #ffffff50;
  font-size: var(--verylittlefont) !important;
  height: 24px;
  cursor: not-allowed;
}

.confirm {
  margin-top: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  background-color: #00000010;
  border: 1px solid #ffffff80;
  width: 50%;
  height: 40px;
  border-radius: 4px;
  font-family: "worksans";
  font-size: var(--verylittlefont);
  font-weight: 400;
}

.visibility {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1000;
  width: 50px;
  height: 50px;

  right: 0;
  bottom: calc(0.5vh + 2px);
}
.img_visibility {
  opacity: 0.6;
  width: 24px;
  filter: invert(1);
}

.img_visibility:hover {
  opacity: 0.9;
}

.notOK {
  border: solid 1px rgb(255, 100, 100);
  border-radius: 3px;
}

.test_alert {
  p {
    margin-top: 4px;
    color: rgb(255, 100, 100);
    font-family: "worksans";
    font-size: var(--verylittlefont);
    font-weight: 500;
  }
}

.button_email {
  margin-left: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4149f6;
  border-radius: 2px;

  max-width: 200px;
  height: 40px;
  opacity: 0.8;
}
.button_email:hover {
  opacity: 1;
}
.passwordsize {
  max-width: none !important;
}

.disabled {
  opacity: 0.5;
  cursor: default;
  &:hover {
    opacity: 0.5 !important;
  }
}

.mandatoryFields {
  font-family: "montserrat";
  font-size: var(--veryverylittlefont);
  font-style: italic;
  opacity: 0.7;
}

.redtext {
  color: var(--redcolor);
}

.disabledInput {
  background-color: var(--bkgdisabled) !important;
  border: 1px solid #5f6368 !important;
}

.companyCodeDiv {
  width: 100%;
  display: inline-block;
  position: relative;
  border: 1px solid #5f6368 !important;
  border-radius: 3px !important;
  input {
    background-color: var(--bkgdisabled) !important;
    border: none !important;
  }
  .icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-left: 1px solid #5f6368;
    height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 50px;
    background-color: var(--bkgconnect);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    cursor: pointer;
    color: white;
    img{
      opacity: 0.7;
    }
  }
  .icon:hover{
    img{
      opacity: 1;
    }
  }
}

.companyCodeFont {
  font-size: var(--verylittlefont) !important;
}
