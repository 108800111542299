//SCANNED

.informationmodal {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1.7;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  padding: 0;
  
  border: 0.5px solid var(--bordercolor);
  border-right: none;
  border-left: none;
  flex-wrap: wrap;
  border-radius: 2px;
  margin: auto;
  
  // margin-top: 7vh;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 3px 5px 6px var(--boxshadow);
  .bkgmodal{
    background-color: var(--backgroundcontent);
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;
    cursor: default;
    opacity: 1;
    backdrop-filter: blur(1px);
    background-color: rgba(241, 241, 241, 0.045);
  }
  p {
    padding: 0;
    margin: 0;
    margin-top: 12px;
    margin-bottom: 12px;
    
    padding-right: 20px;
    padding-left: 20px;
    font-size: var(--littlefont);
  }
}
.paddingmodal{
  padding-top: 50px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.aboutscreenmodal{
width: 70% !important;
height: auto !important;
background-color: var(--menuconnectaccount);
border: 1px solid var(--menuconnectaccountborder);
display: flex;
flex-wrap: wrap;
z-index: 11;
justify-content: center;
align-content: center;
position: relative;
margin-bottom: 60px;
@media (max-width: 576px) {
  height: calc(700px - 45px) !important;
  margin-top: 45px;
}
.closebtn{
  position: absolute;
  right: 20px;
  top: 20px;
  height: 27px;
  width: 27px;
  padding: 0;
  margin: 0;
  svg{
    fill: var(--B_W);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    path{
      fill: var(--B_W);
    }
  }
}
.textabout {
margin: 0;
width: 80%;
height: auto;
text-align: center;
margin-bottom: 25px;
margin-top: 45px;
}
img{
  width: 80%;
  height: auto;
  margin-bottom: 40px;
}
svg{
  cursor: pointer;
  opacity: 0.8;
}
svg:hover{
  opacity: 1;
}
.table{
height: calc(350px + 5vh) !important;
}
}
@import "./newlog.module.scss";

.displayflex{
  display: flex !important;
}
.table{
  margin-top: 30px !important;
}
.nopadding{
  padding-left: 0;
  padding-right: 0;
}
.zoneforbtn{
  width: calc(100% - 50px) !important;
  p{
    margin: 0;
   padding-top: 9px;
   padding-bottom: 9px;
   font-size: var(--veryverylittlefont);
  }
}

.desasociate{
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
justify-content: center;
  svg{
    width: 22px;
    fill: var(--B_W);
  }
}