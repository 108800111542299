.zonemodal {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 34px;
  background-color: var(--backgroundaccount);
  p,
  h2,
  input,
  label,
  div {
    color: var(--B_W);
  }
}

.zonemodal2 {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--backgroundaccount);
  p,
  h2,
  input,
  label,
  div {
    color: var(--B_W);
  }
}

.littlezonemodal {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--backgroundaccount);
}
.closebtn {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 10;
}
.modal {
  width: 390px !important;

  padding-bottom: 10px;
    padding-top: 10px;
  
 
  display: flex;
  flex-wrap: wrap;
  position: relative;
  @media (max-width: 576px) {
    min-height: calc(200px - 45px) !important;
    margin-top: 45px;
  }
  .buttondowloadzone{
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    @media (min-width: 576px) {
      margin-top: 90px !important;
      margin-bottom: 0px !important;
    }
    a{
      background-color: var(--blueapprove);
      border-radius: 2px;
      cursor: pointer;
      padding: 15px 23px;
      transition: 0.1s;
      width: -moz-fit-content;
      width: fit-content;
      width: 290px;
      max-width: 90%;
      text-align: center;
      cursor: pointer;
      z-index: 100;
      text-transform: uppercase;
      font-family: "montserrat";
      font-weight: 500;
      font-size: var(--verybigfont);
      @media (min-width: 576px) {
        font-size: var(--verylittlefont);
      }
    }
    a:hover{
    color: white !important;
    background-color: var(--bluehover);
    }
    a:active{
      color: white !important;
      background-color: var(--bluehover);
      }

  }
 
}
.modal2 {
  width: 590px !important;
  height: 525px !important;
  background-color: var(--menuconnectaccount);
  border: 1px solid var(--menuconnectaccountborder);
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 64px;
  @media (max-width: 576px) {
    height: calc(700px - 45px) !important;
    margin-top: 45px;
  }
}
.modalcachemobile {
  z-index: 9;
  height: 45px;
  top: 0;
  left: 0;
  background-color: var(--backgroundaccount);
  width: 100%;
  border-bottom: 1px solid rgb(52, 52, 52);
  position: fixed;
  @media (min-width: 576px) {
    display: none;
  }
}
@media (max-height: 700px) {
  .zonemodal {
    overflow: scroll;
    align-items: flex-start;
  }
}
@media (max-height: 350px) {
  .littlezonemodal {
    overflow: scroll;
    align-items: flex-start;
  }
}

.multiselect {
  width: 100%;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  font-weight: bold;
}
.selectBox select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: url(../../images/icon/arrow_select.svg) no-repeat right white;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
  background-size: 20px;
  span {
    opacity: 0.8;
  }
}
.opacity8 {
  opacity: 0.7;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  cursor: pointer;
  bottom: 0;
}

.checkboxes {
  border: 1px #545454 solid;
  background-color: var(--checkboxaccount);

  z-index: 10;
  position: absolute;
  width: calc(100% - 30px);
  padding-top: 5px;
  padding-bottom: 5px;
  position: absolute;
  bottom: 35px;
}

.checkboxes label {
  display: flex;
  align-items: center;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  cursor: pointer;
}

.checkboxes label:hover {
  background-color: #004183;
}

@media (min-width: 1500px) {
  .modal {
    width: 650px !important;
    height: 350px !important;
  }
  @media (max-height: 800px) {
    .zonemodal {
      overflow: scroll;
      align-items: flex-start;
    }
  }
}
.littlemodal {
  width: 612px !important;
  height: 310px !important;
}
@media (min-width: 1500px) {
  .littlemodal {
    width: 690px !important;
    height: 310px !important;
  }
}
@media (max-width: 1200px) {
  .littlemodal {
    width: 690px !important;
    height: 300px !important;
  }
}

@media (max-width: 1000px) {
  .littlemodal {
    width: 690px !important;
    height: 280px !important;
  }
}
@media (max-width: 576px) {
  .littlemodal {
    width: 690px !important;
    height: 310px !important;
  }
}

.leftzone {
  height: 100%;
  width: 33%;
  position: relative;
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;

  .bkgimg {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
  }
  .titlezone {
    width: 100%;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
    justify-content: center;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15%;
    .biglogo {
      width: 42%;
    }
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      font-weight: 400;
      font-family: "montserrat";
      font-size: var(--littlefont);
      margin-top: 45px;
    }
  }

  .logozone {
    height: 25%;
    width: 100%;
    z-index: 3;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    .completlogo {
      width: 70%;
    }
  }
}
.rightzone {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
  padding-left: 35px;
  padding-right: 35px;
  p,
  h2,
  input,
  label,
  div {
    color: var(--B_W);
  }
  form {
    width: 100%;
    position: relative;
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    height: 100%;
    .form {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      height: 86%;
      width: 100%;
      .titleform {
        display: flex;
        flex-wrap: wrap;
        padding-top: 25px;
        padding-bottom: 18px;
        position: relative;
        width: 100%;
        h2 {
          width: 100%;
          padding: 0;
          margin: 0;
          text-align: center;
          font-weight: 400;
          font-family: "montserrat";
          font-size: calc(var(--verylittlefont) - 1px);
 
          line-height: 150%;
        }
        p {
          width: 100%;
          padding: 0;
          margin: 0;
          text-align: center;
          font-weight: 300;
          font-family: "montserrat";
          font-size: var(--veryverylittlefont);
          opacity: var(--opacityaccount);
        }
        .separationzone {
          height: 1px;
          width: 100%;
          position: relative;

          .separation {
            bottom: 0px;
          }
        }
        .separation {
          height: 1px;
          width: calc(100% - 60px);
          position: absolute;
          bottom: 10px;
          left: 30px;
          background-color: var(--tile-border);
        }
      }
      .littletitleform {
        padding-bottom: 10px !important;
      }
      .inputzone {
        display: flex;
        flex-wrap: wrap;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 15px;
        padding-left: 15px;
        align-content: space-between;
        label {
          margin: 0;
          padding: 0;
          //text-transform: uppercase;
          font-weight: 500;
          padding-bottom: 3px;
          padding-top: 5px;
          font-family: "montserrat";
          font-size: var(--veryverylittlefont);
        }
        p {
          margin: 0;
          padding: 0;
          //text-transform: uppercase;
          font-weight: 500;
          padding-bottom: 3px;
          padding-top: 5px;
          font-family: "montserrat";
          font-size: var(--veryverylittlefont);
          width: 100%;
        }
        .textlittle {
          text-align: center;
          width: 100%;
          padding: 0;
          margin: 0;
          text-align: center;
          font-weight: 400;
          font-family: "montserrat";
          font-size: calc(var(--verylittlefont) - 1px);
          line-height: 150%;
        }
        .checkbox {
          width: 50%;
          display: flex;
          align-items: center;
          padding-top: 15px;
          padding-bottom: 0px;
          .checkboxbtn {
            width: 17px !important;
            height: 17px !important;
            margin-right: 17px;
          }
        }
        input[type="text"],
        input[type="email"],
        input[type="password"] {
          background-color: var(--checkboxaccount);
          border: 1px solid var(--borderinput);
        

          box-shadow: none;
          margin: 0;
          padding: 0;
          width: 100%;
          min-width: 100%;
          font-weight: 400;
          height: 33px;
          font-family: "montserrat";
          outline: none;
          color: var(--B_W);
          padding-left: 7px;
          padding-right: 7px;
          border-radius: 2px;
          font-size: var(--veryverylittlefont);
        }
        input::placeholder {
          color: rgba(255, 255, 255, 0.688);
        }
        input:hover {
          border-bottom: 1px solid var(--borderhover);
        }
        input:focus {
          border: 1px solid var(--borderhover);
        }

        select {
          background-color: var(--checkboxaccount);
          border: none;
          box-shadow: none;
          margin: 0;
          padding: 0;
          width: 100%;
          min-width: 100%;
          font-weight: 400;
          height: 33px;
          font-family: "montserrat";
          outline: none;
          color: white;
          padding-left: 4px;
          padding-right: 7px;
          border-radius: 2px;
          font-size: var(--veryverylittlefont);
          option:hover {
            background-color: rgb(58, 62, 75);
          }
        }
        select:hover {
          border-bottom: 1px solid var(--borderhover);
        }
        select:focus {
          border: 1px solid var(--borderhover);
        }
      }
    }
    .littleform {
      height: 75%;
      display: block !important;
    }

    .btnzone {
      height: 14%;
      width: 100%;
      z-index: 3;

      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
  
      .twobtn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
      }
      .onebtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
      }
      .compte {
        padding-bottom: 2px;
        padding-top: 2px;

        margin: 0;
   
        cursor: pointer;
        height: 35px;
        min-width: 150px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 2px;
        opacity: 0.8;

        img {
          height: 57% !important;
        }
        p {
          padding: 0;
          margin: 0;
          font-weight: 400;

          font-family: "montserrat";
          color: white;
          font-size: var(--veryverylittlefont);
        }
      }
      .compte:hover {
        opacity: 1;
      }
      .submit {
        padding-bottom: 2px;
        padding-top: 2px;
        padding-left: 20px;
        padding-right: 20px;
        margin: 0;
        cursor: pointer;
        background-color: var(--buttonaccount);
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 2px;
        min-width: 150px;
        input {
          padding: 0;
          margin: 0;
          font-weight: 500;

          font-family: "montserrat";
          color: white;
          background-color: var(--buttonaccount);
          border: none;
          box-shadow: none;
          font-size: var(--veryverylittlefont);
        }
      }
      .submit:hover {
        background-color: var(--buttonaccounthover);
        input {
          background-color: var(--buttonaccounthover);
        }
      }
    }
    .littlebtnzone {
      justify-content: flex-end !important;
      margin-bottom: 30px !important;
      padding-left: 30px;
      padding-right: 30px;
    }
    .verifbtnzone {
      justify-content: center !important;
      margin-bottom: 30px !important;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

.disabled {
  opacity: 0.5;
  cursor: default;
}

.inputSubmit {
  width: calc(100% + 40px);
  height: calc(100% + 4px);
}
.logozoneconnect {
  height: 60px;
  width: 100%;
  z-index: 3;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 17px;
  img {
    height: 100%;
  }
}
.redtext {
  color: var(--redcolor);
}

.contactsupportzone {
  position: absolute;
  left: 35px;
  top: -40px;
  z-index: 100;
  @media (max-width: 576px) {
    top: 15px;
  }
  a {
    p {
      border-bottom: 1px solid white !important;
      font-weight: 400;
      font-family: "montserrat";
      margin: 0;
      font-size: var(--veryverylittlefont);
    }
    p:hover {
      border-bottom: 1px solid var(--select) !important;
    }
  }
}
@media (max-width: 576px) {
  .changelanguagezone {
    height: 40px;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    width: 100%;
  }
}
.changelanguagezone {
  display: flex;
}
.changelanguage {
  border: none;

  padding: 0;
  font-family: "montserrat", "poppins";
  position: relative;
  color: white;
  background-color: transparent;

  outline: none !important;
  margin-right: 35px;

  position: fixed;
  right: 35px;
  top: 20px;
  text-transform: capitalize;
  z-index: 10;
  @media (max-width: 576px) {
    top: 15px;
  }
  img {
    opacity: 1;
    width: 7px;
    margin-left: 10px;
    background-color: transparent;
    filter: var(--invertbis);
    margin-bottom: 1px;
  }
  .rotate {
    transform: rotate(180deg);
  }
  .button {
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: transparent;
    color: white;
    outline: none !important;
    display: none;
    font-weight: 400;
    position: absolute;
    top: 27px;
  
    height: 30px;
    align-items: center;
    flex-wrap: wrap;
    width: 23px;
    justify-content: center;
    background-color: #0000002e;
    p {
      font-size: calc(14px);
      margin: 0;
      width: 100%;
      height: 100%;
      justify-content: flex-start;
      align-items: center;
      display: flex;
      color: white;
      height: 35px;
      padding: 0;
    }
  }
  .view {
    display: flex;
  }
  button:hover {
    font-weight: 500;
  }
}
#country {
  -webkit-appearance: none;
  appearance: none;
  background: url(../../images/icon/arrow_select.svg) no-repeat right white;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
  background-size: 20px;
}
.dispnonew {
  display: var(--displaynonew);
}
.dispnoneb {
  display: var(--displaynoneb);
}

.span_eye {
  height: 33px;
  bottom: 5px;
  position: absolute;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  cursor: pointer;
  img {
    width: 23px;
    filter: invert(1);
    opacity: 0.8;
  }
}
.heightbottom {
  height: 34px;

  bottom: 0;
  position: absolute !important;
  right: 0;
  padding-top: 2px !important;
  padding-bottom: 0 !important;
  width: 100%;
  padding-right: 35px !important;
  display: flex;
  align-items: flex-start;
  background-color: var(--bkgconnect);
  z-index: 101;
  justify-content: flex-end;
  a {
    font-size: calc(var(--veryveryverylittlefont));
    opacity: 0.6;
  }
}
