.menu {
  position: relative;
  top: 0px;
  height: 100vh;
  width: 49px;
  // min-width: 200px;
  // max-width: 240px;
  transition: 0.4s;
  background-color: var(--menuconnect);

  //  background-color: var(--navleft);
  z-index: 0;
  
  p {
    color: var(--B_W) !important;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // background-color: var(--menuconnect);
    width: 100%;
    // min-width: 200px;
    // max-width: 240px;
    //  margin-top: 65px;
    height: calc(100% - 65px);

    .organisation {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 46px;
      width: 80%;
      background-color: #00000000;
      border-radius: 12px;
      margin: 2px 0px;

      p {
        display: flex;
        margin: 0;
        font-size: var(--littlefont);
        font-weight: 600;
        padding: 30px;
      }
    }
    .organisation:hover {
      background-color: #00000075;
      cursor: pointer;
    }
    .organisation:active {
      background-color: #121212;
      cursor: pointer;
    }
    .logocontainer {
      display: flex;
      width: 80%;

      .logomgi {
        display: flex;

        height: auto;
        height: 64px;
        align-items: center;
        width: 100%;
        background-color: none;
        overflow: hidden;

        img {
          fill: var(--B_W);
          height: 40px;
          // width: 35px;

          margin-left: 1px;
        }

        p {
          display: flex;
          font-family: "Young";
          font-size: var(--verylittlefont);
          font-weight: 500;
          padding: 30px;
          white-space: nowrap;
          margin: 0;
          padding: 0;
          margin-left: 15px;
          margin-top: 6px;
        }
      }
    }
    .logomgicontainer {
      display: flex;
      width: 80%;
      position: absolute;
      bottom: -6px;
      flex-wrap: wrap;
      .logomgibot {
        display: flex;

        height: auto;
        height: 64px;
        align-items: center;
        width: 100%;
        background-color: none;
        overflow: hidden;

        img {
          opacity: 0.9;
          fill: var(--B_W);
          height: 34px;
          width: 34px;
          margin-left: 1px;
        }
        p {
          opacity: 0.6;
          display: flex;
          // font-family:"Young";
          font-size: calc(0.1em + var(--veryverylittlefont));
          letter-spacing: -1px;
          font-weight: 450;
          padding: 30px;
          white-space: nowrap;
          margin: 0;
          padding: 0;
          margin-left: 6px;
          margin-top: calc(4px);
        }
      }
    }

    .container_list {
      display: flex;
      height: calc(100% - 50px);
      width: 80%;
      overflow: scroll;

      .list1 {
        display: flex;

        height: auto;
        width: 100%;
        background-color: none;

        p {
          display: flex;
          align-items: center;
          margin: 0;

          white-space: nowrap;
        }

        img {
          transform: scale(0.75);
        }

        ul {
          width: 100%;
          margin: 0;
          padding: 0;
        }
        .lilith {
          //  width: 100%;
          display: flex;
          cursor: pointer;
          opacity: 0.8;
          .svgzone {
            height: 30px;
            min-width: 20px;
            margin-left: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              margin-left: 0;
              width: 18px;
              min-width: auto;
            }
          }
          svg {
            fill: var(--B_W);
            height: 30px;
            min-width: 19px;
            margin-left: 10px;
            path {
            }
          }
        }
        .lilith:active {
          color: var(--select);
          svg {
            fill: var(--select);
            opacity: 0.9;
          }

          .path {
            opacity: 0.9;
            path {
              fill: var(--select) !important;
            }
          }
        }
        .hblilith:active {
          color: var(--hbcolor);
          svg {
            fill: var(--hbcolor);
            opacity: 0.9;
          }

          .path {
            opacity: 0.9;
            path {
              fill: var(--hbcolor) !important;
            }
          }
        }
        .lilith:hover {
          opacity: 0.9;
          svg {
            opacity: 0.9;
          }
          .svgzone {
            svg {
              opacity: 0.9;
            }
          }
        }

        li.li1 {
          flex-wrap: wrap;
          width: 100%;
          display: flex;
          height: auto;
          padding-top: 18px;
          list-style: none;
          font-size: var(--veryverylittlefont);
          font-weight: 600;

          .text_select {
            opacity: 0.9;
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-left: 18px;
            cursor: pointer;
            white-space: nowrap;
            img {
              opacity: 0.3;
              transform: scale(0.75);
              filter: var(--remy);
            }
            svg {
              opacity: 0.3;
              transform: scale(0.75);
            }
          }

          .text_select:hover {
            opacity: 1;
            img {
              opacity: 0.9;
            }
            svg {
              opacity: 0.9;
            }
          }

          .btn_select {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-left: 20px;
            padding-left: 35px;
            cursor: pointer;
            white-space: nowrap;
            img {
              opacity: 0.3;
              transform: scale(0.75);
              filter: var(--remy);
            }
            svg {
              opacity: 0.3;
              transform: scale(0.75);
            }
          }
        }
      }
      .text_select:active {
        color: var(--select) !important;
        img {
          opacity: 1;
        }
        svg {
          opacity: 1;
        }
      }
      .active {
        color: var(--select) !important;
        svg {
          fill: var(--select) !important;
        }
        p,
        a {
          color: var(--select) !important;
        }
        .svgzone {
          .path {
            color: var(--select) !important;
            path {
              fill: var(--select) !important;
            }
          }
        }
      }

      .hbactive {
        color: var(--hbcolor) !important;
        svg {
          fill: var(--hbcolor) !important;
        }
        p,
        a {
          color: var(--hbcolor) !important;
        }
        .svgzone {
          .path {
            color: var(--hbcolor) !important;
            path {
              fill: var(--hbcolor) !important;
            }
          }
        }
      }
    }

    li {
      list-style: none;
    }

    .li2 {
      opacity: 0.8;
      list-style: none;
      width: 70%;
      margin-left: 38px;
      margin-top: 6px;
      font-weight: 500;
      cursor: pointer;
      color: var(--B_W);
    }
    p {
      font-size: var(--veryverylittlefonttable);
    }
  }
  .li2:hover {
    opacity: 0.8;
    color: var(--select);
  }
  .li2:active {
    opacity: 0.9;
    color: var(--select);
  }
}

.openMenu {
  display: none;
}
.fliparrow {
  transform: rotate(180deg) scale(0.75) !important;
}

a {
  color: var(--B_W) !important;
  text-decoration: none !important;
  opacity: 1;
}
a:hover {
  color: var(--select) !important;
  text-decoration: none !important;
  opacity: 1;
}

.menu:hover {
  width: calc(215px + 3.4vw);
}

.whitedarkmode {
  position: absolute;
  bottom: 55px;
  overflow: hidden;
  width: 80%;
  color: var(--B_W) !important;
  .lilith {
    // width: 88%;
  }
}

.bkgbtn {
  height: 23px;
  width: 45px;
  background-color: var(--blue);
  border-radius: 100px;
  //border: 1px solid var(--borderbtn);
  right: 0;
  display: flex;
  align-items: center;
  position: relative;
  .btninterieur {
    background-color: var(--B_W);
    border-radius: 100px;
    height: 17px;
    width: 17px;
    position: absolute;
    right: 5px;
    transition: 0.3s;
  }
}
.bkgbtnhb {
  height: 23px;
  width: 45px;
  background-color: var(--hbcolor);
  border-radius: 100px;
  //border: 1px solid var(--borderbtn);
  right: 0;
  display: flex;
  align-items: center;
  position: relative;
  .btninterieur {
    background-color: var(--B_W);
    border-radius: 100px;
    height: 17px;
    width: 17px;
    position: absolute;
    right: 5px;
    transition: 0.3s;
  }
}
.btnwhite {
  background-color: white;
  //border: 1px solid var(--borderbtn);
  .btninterieur {
    background-color: rgb(112, 112, 112);

    right: 20px;
  }
}
.separationbar {
  height: 1px;
  background-color: var(--B_W);
  width: 95.9%;
  margin-top: 18px;
  opacity: 0.3;
}
@media (max-width:720px){
  .menu {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10000;
   
    top: 0;
    &:hover{
      width: 100%;
    }
    .container{
      p{
        font-size: 18px;
      }
    }
  }
  .menuclose{
    left: -100%;
  }
  .menuopen{
    left: 0%;
  }




  .container_list .list1 li.li1 .lilith{
    width: 100%;
  }
  .closemenubtn{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 30px;
    top: 15px;
  }
}

.menuclosebtn{
  display: none;
}
  @media (max-width:720px){
    .menuclosebtn{
      width: 20px;
      height: 20px;
      position: absolute;
      right: 35px;
      top: 20px;
      display: flex;
      z-index: 1000;
      svg{
        width: 100%;
        height: 100%;
        fill: white;
      }
    }
  }