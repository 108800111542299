@import "./variable.scss";
.container {
  flex-wrap: wrap;
  overflow: scroll;
  background-color: var(--bkgconnect);
  height: calc(100vh - 60px);
  // width: 84vw;
  width: 100%;
  align-content: flex-start;

  // margin-left: 16vw;
  padding: 0 30px;

  @media (max-width: 1250px) {
    // margin-left: 200px;
    // width: calc(100vw - 200px);
  }

  @media (min-width: 1552px) {
    // margin-left: 240px;
    // width: calc(100vw - 240px) !important;
  }

  @media (max-width: 576px) {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 100;
    padding-top: 40px;
    padding-bottom: 35px;
    .bluetitle {
      p {
        margin-bottom: -25px !important;
      }
    }
    .titleprinc {
      padding-bottom: 15px !important;
    }
    .wrapperzone {
      height: 130px;
      max-width: 400px;
      margin: auto;
    }
    .wrapper {
      display: block !important;
      .option {
        margin-right: 0;
        height: 50px;
        margin-bottom: 4px;
      }
    }
    .onecontentzone {
      margin-bottom: 30px;
    }
    .contenttextarea {
      margin-bottom: 32px !important;
      label {
        margin-bottom: 17px;
      }
    }
    .soumettre {
      bottom: -20px !important;
      right: 7px !important;
    }
  }

  div {
    margin: 0;
    padding: 0;
  }
  .titleprinc {
    padding-top: 15px;
    padding-bottom: 25px;
    h2 {
      font-size: var(--verybigfont);
      font-family: "Poppins";
      font-weight: 500;
    }
  }

  .onecontentzone {
    padding: 0;
  }
  .onecontent {
    padding: 0;
  }
  .firstcontent {
    padding: 0;
  }

  .title {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    p {
      margin: 0 !important;
    }
    .titletext {
      width: 100%;
      font-size: var(--medfont);
      font-weight: 500;
      font-family: "worksans";
      height: 38px;
      display: flex;
      align-items: center;
    }
    .name {
      margin-left: 20px !important;
      font-size: var(--bigfont);
      font-weight: 500;
    }
  }

  .bluetitle {
    // padding-top: 30px;
    padding-bottom: 5px;
    p {
      height: 40px;
      margin: 0;
      padding: 0;
      color: var(--B_W);
      text-transform: uppercase;
      display: flex;
      align-items: center;
      margin: 0;
      font-size: var(--verylittlefont);
      font-weight: 500;
      font-family: "worksans";
    }
  }
  .paddingtitle {
    padding-right: calc(15px);
  }

  .compte {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #4149f6;
    border: solid 1px #4149f6;
    border-radius: 100%;

    height: 38px;
    width: 38px;

    .letter {
      font-size: 17px;
      font-family: "Poppins";
      font-weight: 600;
      color: white;
      margin: 0;
      padding: 0;
      letter-spacing: 1px;
    }
  }
  .comptebig {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #4149f6;
    border: solid 1px #4149f6;
    border-radius: 100%;

    height: 58px;
    width: 58px;
    cursor: pointer;
    overflow: hidden;

    .letter {
      font-size: 25px;
      font-family: "Poppins";
      font-weight: 500;
      color: white;
      margin: 0;
      padding: 0;
      letter-spacing: 1px;
    }
  }
  .comptechange {
    width: calc(100% - 100px);
    label,
    input,
    p {
      font-size: var(--veryveryverylittlefont);
      font-weight: 400;
      font-family: "worksans";
    }
    label {
      // background-color: ;
      padding-left: 7px;
      padding-right: 7px;
      padding-top: 3px;
      padding-bottom: 3px;
      border-radius: 2px;
      border: 0.5px solid var(--choosefile_border);
      cursor: pointer;
      transition: 0.1s;
      margin: 0;
      float: right;
      font-weight: 500;
    }
    label:hover {
      background-color: var(--bluehover);
      border: 1px solid var(--bluehover);

      color: white;
    }
  }
  .changeimg {
    .imgprofile {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      border-radius: 0;
      margin: 0;
      .photo {
        position: absolute;
        bottom: 0;
        border-top: none;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: 0.1s;
        img {
          filter: invert(1);
          width: 17px;
        }
      }
    }
    .imgprofile:hover {
      .photo {
        position: absolute;
        bottom: 0;
        border-top: 2px solid rgba(76, 81, 126, 0.158);
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        opacity: 0;
        background-color: rgba(46, 52, 77, 0.192);

        opacity: 0.9;

        img {
          filter: invert(1);
          width: 17px;
        }
      }
    }
  }

  .label {
    font-size: var(--veryveryverylittlefont);
    font-weight: 400;
    font-family: "worksans";
    width: 100%;
  }
  .firstcontent {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }
  .onecontentzone {
    display: flex;
    flex-wrap: wrap;

    padding-right: calc(15px);

    .onecontent {
      display: flex;
      flex-wrap: wrap;
      // padding-right: 10px;
    }
    .oneform {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: calc(0.5vh + 2px);
      padding-top: calc(0.7vh + 0px);
      height: auto;
      label {
        text-transform: uppercase;
        font-size: var(--veryverylittlefont);
        color: rgba(255, 255, 255, 0.732);
        font-weight: 500;
        font-family: "worksans";
      }
      input {
        color: var(--B_W);
        background-color: var(--backg_transparent);
        border: 1px solid var(--menuconnect);
        width: calc(100% - 15px);
        height: 50px;
        border-radius: 6px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: var(--medfont);
        font-weight: 400;
        font-family: "worksans";
        outline: none;
        display: flex;
        align-items: center;
      }
    }
  }
  .input {
    background-color: #00000060;
    width: 100%;
    height: 36px;
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: var(--medfont);
    font-weight: 400;
    font-family: "worksans";
    outline: none;
    display: flex;
    align-items: center;
    p {
      margin: 0;
    }
  }
  input[type="file"] {
    display: none;
  }
  .space {
    width: 100%;
    p {
      margin: 0;
      height: 40px;
    }
  }
}

.changeimg {
  margin-top: calc(2vh + 22px) !important;
  background-color: var(--backg_transparent);
  border: 1px solid var(--menuconnect);
  border-radius: 6px;
  padding-bottom: calc(1vh + 15px) !important;
  padding-top: calc(1vh + 15px) !important;
  padding-left: calc(15px) !important;
  padding-right: calc(15px) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.theme {
  border: #00000000 solid 0.5px;
  border-radius: 4px;
  background-color: #00000000;
  margin-top: 100px !important;
  padding-bottom: 38px !important;
  font-size: var(--littlefont) !important;
}
.display_flex {
  display: flex;
  justify-content: space-between;
}
.no_border {
  border: 1px solid var(--menuconnect) !important;
  font-size: var(--littlefont) !important;
  p {
    color: var(--B_W);
  }
}
.no_padding {
  padding: 0px !important;
  margin-top: 10% !important;
}
.vingt {
  width: 20% !important;
  text-transform: uppercase;
  color: var(--Title2);
  padding-top: 8px;
  font-weight: 500;
  p {
    font-size: var(--littlefont) !important;
    color: var(--B_W);
  }
}
.quatre_vingt {
  width: 70% !important;
  position: relative;

  background-color: var(--backg_transparent) !important;
  border: 1px solid var(--menuconnect);
  p {
    font-size: var(--littlefont) !important;
    color: var(--B_W);
  }
}

.changedarklight {
  position: absolute;
  right: 15px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  .darkbtn {
    border: 1.5px solid rgb(199, 199, 199);
    width: 17px;
    height: 17px;
    cursor: pointer;
    border-radius: 100px;
    background-color: black;
    margin-left: 4px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
  }
  .lightbtn {
    border: 1.5px solid rgb(41, 41, 41);
    width: 17px;
    height: 17px;
    cursor: pointer;
    border-radius: 100px;
    background-color: white;
    margin-left: 4px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bluebtn {
    border: 2px solid var(--bluehover);
    width: 17px;
    height: 17px;
    cursor: pointer;
    border-radius: 100px;
    background-color: var(--bluehover);
    margin-left: 4px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .selected {
    border: 2px solid var(--bluehover);
    width: 25px;
    height: 25px;
    position: absolute;
    border-radius: 200px;
  }
}

.littlething {
  color: #ffffff;
  font-weight: 400;
  font-size: var(--verylittlefont) !important;
  height: 24px;
  cursor: pointer;
}

.notClickableLittlething {
  font-weight: 400;
  color: #ffffff50;
  font-size: var(--verylittlefont) !important;
  height: 24px;
  cursor: not-allowed;
}

.confirm {
  margin-top: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  background-color: #00000010;
  border: 1px solid #ffffff80;
  width: 50%;
  height: 40px;
  border-radius: 4px;
  font-family: "worksans";
  font-size: var(--verylittlefont);
  font-weight: 400;
}

.visibility {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1000;
  width: 50px;
  height: 50px;

  right: 0;
  bottom: calc(0.5vh + 2px);
}
.img_visibility {
  opacity: 0.6;
  width: 24px;
  filter: invert(1);
}

.img_visibility:hover {
  opacity: 0.9;
}

.notOK {
  border: solid 1px rgb(255, 100, 100);
  border-radius: 6px;
}

.test_alert {
  p {
    margin-top: 4px;
    color: rgb(255, 100, 100);
    font-family: "worksans";
    font-size: var(--verylittlefont);
    font-weight: 500;
  }
}

.button_email {
  margin-left: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4149f6;
  border-radius: 2px;

  max-width: 200px;
  height: 40px;
  opacity: 0.8;
}
.button_email:hover {
  opacity: 1;
}
.passwordsize {
  max-width: none !important;
}
.listapps {
  width: 100%;
  height: auto;
  li {
    display: flex;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background-color: hsla(0, 0%, 100%, 0.019);
      border: 1px solid rgba(255, 255, 255, 0.358);
      padding: 0;
      margin: 0;
      width: calc(100% - 25px) !important;
      cursor: pointer;
      opacity: 0.4;
      transition: 0.15s;
      img {
        height: 57px;
        padding-top: 15px;
        padding-bottom: 15px;
        max-width: 85%;
      }
    }
    div:hover {
      opacity: 0.7;
    }
  }
  .selected {
    div {
      opacity: 1;
    }
    div:hover {
      opacity: 1;
    }
  }
}
.contenttextarea {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* align-items: flex-start; */
  align-content: flex-start;
  position: relative;
  padding-left: 7px !important;
  padding-right: 0px !important;
  label {
    height: 40px;
    margin: 0;
    padding: 0;

    color: var(--B_W);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin: 0;
    font-size: var(--verylittlefont);
    font-weight: 500;
    font-family: "worksans";
  }
  textarea {
    width: 100%;
    height: calc(100% - 95px);
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    resize: none;
    font-size: var(--medfont);
    font-weight: 400;
    font-family: "worksans";
    color: var(--B_W);
    background-color: var(--backg_transparent);
    margin-bottom: 45px;
    border: 1px solid var(--supportborder);
  }
  .soumettre {
    position: absolute;
    right: 0px;
    bottom: 0;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blueapprove);
    opacity: 0.8;
    height: 40px;
    line-height: 0;
    cursor: pointer;
    color: #ffffff;
    font-weight: 400;
    font-size: var(--verylittlefont) !important;
    border: none;
  }
  .soumettre:hover {
    opacity: 1;
  }
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0;
    line-height: 0;
    color: #ffffff;
    font-weight: 400;
    width: 100%;
    font-size: var(--verylittlefont) !important;
  }
}
.wrapperzone {
  display: flex;
  // justify-content: center;
}
.wrapper {
  display: inline-flex;

  height: 50px;
  width: calc(499px + 0.5vw);
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  padding: 20px 15px;
  margin-bottom: 15px !important;
  margin-top: 15px !important;
}
.wrapper .option {
  background: black;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0;
  margin-right: 15px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 1px solid rgb(171, 171, 171);
  transition: all 0.3s ease;
  box-shadow: 5px 5px 15px rgb(0 0 0 / 20%);
}
.wrapper .option .dot {
  height: 13px;
  width: 13px;
  background: #e5e5e5;
  border-radius: 50%;
  position: relative;
  display: flex;
}
.wrapper .option .dot::before {
  content: "";

  width: 7px;
  height: 7px;
  background: var(--blueapprove);
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
  margin: auto;
  display: flex;
  align-self: center;
}
input[type="radio"] {
  display: none;
}
.supportbtn:checked ~ .option1,
.commercialbtn:checked ~ .option2 {
  border-color: var(--blueapprove);
  background: var(--blueapprove);
}
.supportbtn:checked ~ .option1 .dot,
.commercialbtn:checked ~ .option2 .dot {
  background: #fff;
}
.supportbtn:checked ~ .option1 .dot::before,
.commercialbtn:checked ~ .option2 .dot::before {
  opacity: 1;
  transform: scale(1);
}
.wrapper .option span {
  font-size: var(--veryverylittlefont);
  color: #ffffff;
  font-family: "montserrat";
  font-weight: 400;
  margin-top: 1px;
}
.supportbtn:checked ~ .option1 span,
.commercialbtn:checked ~ .option2 span {
  color: #fff;
}
