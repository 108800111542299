.welcom {
  padding: 0 0px !important;
  .primary_btn {
    display: flex;
    font-weight: 550;
    font-size: var(--veryverylittlefont);
    justify-content: center;
    align-items: center;
    height: 30px;
    background-color: #4149f6;
    width: 120px;
    border-radius: 1px;
  }

  width: 100%;
  height: 40px;
  // background-color: var(--welcom);

  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--littlefont);
  font-weight: 450;
  margin: 0px 0px 0px 0px;
  border-bottom: solid 0.5px var(--tile-border);
  padding: 0px 0px;
  p {
    text-align: center;
    margin: 0;
  }
  span {
    color: #849bff;
  }
}

.boxwelcom {
  padding: 0px 6px !important;
}
.container {
  flex-wrap: wrap;
  overflow: scroll;
  display: flex;
  background-color: var(--bkgconnect);
  height: calc(100vh - 104px);
  // width: 84vw;
  align-content: flex-start;

  .float_left {
    float: left;
  }

  p {
    margin: 0;
    padding: 0;
  }
  span {
    color: var(--select) !important;
  }

  // margin-left: 16vw;
  padding: 0 24px;

  @media (max-width: 1250px) {
    // margin-left: 200px;
    // width: calc(100vw - 200px);
  }

  @media (min-width: 1552px) {
    // margin-left: 240px;
    // width: calc(100vw - 240px) !important;
  }

  .padding {
    padding: 5px 5px;
  }

  .align {
    align-items: center;
  }

  .approvecolor {
    background: linear-gradient(180deg, #0f7ecd 1%, #0f94cd);
  }

  .dashboardcolor {
    background: linear-gradient(180deg, #dd9a08 1%, #fbb505);
  }
  .jetforcecolor {
    background: linear-gradient(180deg, #91b920 1%, #9ac32a);
  }
  .estimatecolor {
    background: linear-gradient(180deg, #7b56c2 1%, #7b56c2);
  }
  .toolscolor {
    background-color: var(--toolsbkg);
  }

  .logoPredict {
    height: calc(50px + 0.5vw) !important;
  }
  .registertoolcolor {
    background: linear-gradient(0.25turn, #82d0e6, 55%, #6dbbd1);
    .bglogo {
      height: calc(45px + 0.5vw) !important;
    }
  }
  .predicttoolcolor {
    background: linear-gradient(0.25turn, #018b86, 55%, #00968f);
    .bglogo {
      height: calc(45px + 0.5vw) !important;
    }
  }
  .tile {
    position: relative;
    margin-top: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    vertical-align: center;
    width: 100%;
    height: calc(100% - 0px);

    border-radius: 10px;
    border: 0.5px solid var(--tile-border);
    box-shadow: 5px 6px 14px var(--tile-box-shadow);

    .topbar {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      //      background-color: var(--topbar);
      z-index: 2;
      padding: 0 16px;
      &:hover {
        opacity: 1;
      }
      p {
        padding-top: 0px !important;
        font-size: var(--veryverylittlefont);
        opacity: 0.9;
        font-weight: 600;
        // color: var(--B_W);
        color: white;
        text-transform: capitalize;
      }

      @media (max-width: 1140px) {
        padding: 0 14px;
      }

      img {
        padding: 0px !important;
        opacity: 0.6;
        // height:35px;
        height: 60%;
        margin: 0;
        z-index: 20;
        :hover {
          opacity: 0;
          z-index: 20;
        }
      }

      .extopen {
        display: flex;
        align-items: center;
        opacity: 0.8;

        // height:35px;
        height: 100%;
        margin: 0;
        img {
          height: 50%;

          @media (max-width: 1140px) {
            height: 60%;
          }
        }
      }
      .open {
        margin: 0px;
        padding: 0px;
        flex-wrap: nowrap;
        display: flex;
        justify-content: flex-end;
        p {
          display: flex;
          padding-top: 2px !important;
          font-size: var(--veryverylittlefont);
          font-weight: 600;
          // color: var(--B_W);
          color: white;
          text-transform: capitalize;
          cursor: pointer;
        }
        img {
          opacity: 1;
          // height:35px;
          height: 14px;
          margin: 0;
          padding: 0px 0 0 3px;
          margin-left: 5px;
          margin-top: 6px;
        }
      }
    }
    .toolbar {
      background-color: #ffffff0e;
    }

    .bottomzone {
      background-color: rgba(255, 255, 255, 0.03);
      flex-wrap: nowrap;
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      height: 40px;
      //      background-color: var(--topbar);
      z-index: 2;
      padding: 0px 14px 4px 14px;
      .open {
        margin: 0px;
        padding: 0px;
        flex-wrap: nowrap;
        display: flex;
        justify-content: flex-end;
      }
      p {
        display: flex;
        padding-top: 2px !important;
        font-size: var(--veryverylittlefont);
        font-weight: 600;
        // color: var(--B_W);
        color: white;
        text-transform: capitalize;
        cursor: pointer;
      }

      @media (max-width: 1140px) {
        padding: 0 12px;
      }

      img {
        opacity: 1;
        // height:35px;
        height: 14px;
        margin: 0;
        padding: 0px 0 0 3px;
        margin-left: 5px;
        margin-top: 6px;
      }
      .extopen {
        display: flex;
        align-items: center;
        opacity: 0.8;

        // height:35px;
        height: 100%;
        margin: 0;
        img {
          height: 50%;

          @media (max-width: 1140px) {
            height: 60%;
            img {
              margin-top: 6px;
            }
          }
        }
      }
    }

    .botbar {
      position: absolute;
      bottom: 0;
      height: 15%;
      background-color: var(--bottom-bar);
      z-index: 2;
      padding: 0;
      display: flex;
      padding: 0 16px;

      align-items: center;
      justify-content: center;

      @media (max-width: 1140px) {
        padding: 0 10px;
        font-size: var(--veryverylittlefont);
      }
      img {
        opacity: 1;
        width: 20px;
        margin: 0;
      }
      p {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: var(--verylittlefont);
        margin: 0;
        padding: 0;
        font-weight: 500;
      }
    }

    img {
      opacity: 1;
      display: flex;
      padding: 0;
      margin-top: 0;
      height: 100%;
    }
    .light {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;

      z-index: 1;
      box-shadow: inset 3px 3px 5px rgba(250, 250, 250, 0.2);
    }
    .btnplay {
      width: 50px;
      height: 50px;
      img {
        width: 100%;
      }
    }
  }

  .ht0 {
    height: 14%;
    min-height: 110px;
  }
  .httools {
    height: 18%;
    min-height: 150px;
  }
  .ht1 {
    height: 28%;
    min-height: 220px;
  }
  .ht2 {
    height: 55.99%;
    min-height: 440px;
  }

  .ht3 {
    height: 84%;
    min-height: 600px;
  }

  .ht0sm {
    @media (max-width: 992px) {
      height: 14%;
      min-height: 110px;
    }
  }
  .httoolssm {
    @media (max-width: 1600px) {
      height: 18%;
      min-height: 150px;
    }
  }

  .ht1sm {
    @media (max-width: 1600px) {
      height: 28%;
      min-height: 220px;
    }
  }

  .ht2sm {
    @media (max-width: 1600px) {
      height: 56%;
      min-height: 440px;
    }
  }

  .ht3sm {
    @media (max-width: 1600px) {
      height: 84%;
      min-height: 600px;
    }
  }

  .light:hover {
    background-color: var(--lightselect);
    transition: 0.1s;
  }
  .cursorpointer {
    cursor: pointer;
  }

  .btn {
    display: flex;
    justify-content: center;
    vertical-align: center;
    position: absolute;
    height: 30px;
    width: auto;
    background-color: none;
    bottom: 6%;
    right: 16px;
    border: 1px solid #3b3b3b80;
    background-color: var(--with);
    color: var(--B_W);
    border-radius: 4px;

    @media (max-width: 992px) {
      height: 26px;
      bottom: 5%;
      right: 8px;
    }

    p {
      display: flex;
      align-items: center;
      text-align: center;
      padding: 2px 10px;
      font-size: var(--veryverylittlefont);
    }
  }

  .btn:hover {
    background-color: #3b3b3b30;
    cursor: pointer;
  }
  .btn:active {
    background-color: #3b3b3b50;
    cursor: pointer;
  }

  .app {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      width: 60%;
      height: 60%;
      margin: 0;
    }
  }
  .app_metrics {
    margin-top: 34px;
    height: calc(100% - 34px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
  }
  .iconzone {
    top: 0 !important;
    margin-top: 0 !important;
    height: 100%;
    padding: 0px 16px !important;
  }
  .content {
    overflow: scroll;
    position: absolute;
    top: 0;
    padding: 0px 16px;
    margin-top: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(97%);
    img {
      height: calc(70px + 0.5vw);
    }
    .estimateLogo {
      height: calc(25px + 0.5vw) !important;
    }
    .content_icon {
      justify-content: center;
      // width: 100%;
      // height: 100%;
      // max-width: 320px;
      width: 200px;
      height: 200px;
      margin: auto;
      max-width: 200px;
      max-height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      ul {
        // padding: 0 16px;
        padding: 0;
        list-style: none;
        margin: 0;
        width: 100%;
        height: 100%;
        li {
          padding: 0px 1px;
          display: flex;
          height: 66px;
          justify-content: space-between;
        }
      }
    }

    ul {
      padding: 6% 16px;
      list-style: none;

      margin: 0;
      li {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
        p {
          width: fit-content;
          width: calc(100% - 24px);
        }
      }
    }
    p {
      font-size: calc(var(--veryverylittlefont) + 0.1rem);
      line-height: 180%;
      font-weight: 400;
    }
    @media (max-width: 992px) {
      margin-top: 1%;
      line-height: 140%;
    }
    @media (max-width: 1140px) {
      padding: 0 10px;
    }
  }
}
.onecontentzone {
  width: 20%;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
  position: relative;
  .titlecontentzone {
    margin: 0;
    padding: 0;
    font-size: var(--verylittlefont);
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: flex-end;
    width: 100%;
    height: 30px;

    position: absolute;
    bottom: 10px;
  }

  .dashtrs {
    height: calc(100% - 30px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    path {
      stroke: transparent;
    }
    p {
      position: absolute;
      font-size: var(--verylittlefont);
      font-weight: 400;
      color: var(--B_W);

      margin: 0;
      padding: 0;
    }

    .hoursvalue {
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: fit-content;

      perspective-origin: right;
      p {
        position: relative;
        color: var(--B_W);
      }
    }
  }
}
.onecontentzoneoee {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
  position: relative;

  .dashtrs {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 13px;
    path {
      stroke: transparent;
    }
    .doublegraph {
      padding-bottom: 0 !important;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      align-content: center;
      .linedashzone {
        position: relative !important;
        margin-bottom: 10px;
        margin-top: 10px;
        bottom: auto !important;
        .titleline {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          padding-left: 15px;
          padding-right: 15px;
          padding-bottom: 8px;
          p {
            font-size: var(--verylittlefont);
            font-weight: 400;
            color: var(--B_W);

            margin: 0;
            padding: 0;
          }
        }
      }
    }
    .numberzone {
      margin: 0;
      padding: 0;
      width: 100%;
      padding-bottom: 52px;
      .number {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 12px;
        padding-right: 12px;
        width: 100%;

        .bigfont {
          font-size: var(--veryverybigfont);
          font-weight: 400;
          color: var(--B_W);

          margin: 0;
          padding: 0;
          font-weight: 500;
          letter-spacing: 3px;
        }
        .littlefont {
          font-size: var(--bigfont);
          font-weight: 400;
          color: var(--B_W);

          margin: 0;
          padding: 0;

          margin-top: 10px;
        }
      }
      .compteutilisateur {
        font-size: var(--veryverylittlefont);
        font-weight: 300;
        color: var(--B_W);
        margin: 0;
        padding: 0;
        padding-left: 12px;
        padding-right: 12px;
      }
    }
    .titlecontentzoneimportant {
      position: absolute;
      font-size: var(--verylittlefont);
      font-weight: 400;
      color: var(--B_W);

      margin: 0;
      padding: 0;
    }
    .hoursvalue {
      position: absolute;
      bottom: 15px;
      right: 15px;
      width: fit-content;

      perspective-origin: right;
      p {
        position: relative;
        font-size: var(--veryverylittlefont);
        opacity: 0.6;
        color: var(--B_W);
      }
    }
  }
}
.rounddash {
  svg {
    height: 100%;

    rect {
      //     fill: var(--colorgraph);
      fill: transparent;
    }
  }
}
.linedashzone {
  bottom: 15px;
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0;

  .linedash {
    left: 15px;
    width: calc(100% - 30px);
    height: 4px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 100px;
    margin: auto;
    .value {
      height: 100%;
      width: 17%;
      border-bottom-left-radius: 100px;
      border-top-left-radius: 100px;
    }
    .bluevalue {
      background-color: #4149f6;
    }
    .greenvalue {
      background-color: #15df82;
    }
    .background {
      height: 100%;
      width: calc(100% - 17%);
      background-color: var(--dashboard_bckg);
      border-bottom-right-radius: 100px;
      border-top-right-radius: 100px;
    }
  }
  .linefont {
    margin: 0;
    padding: 0;
    opacity: 0.6;
    color: var(--B_W);
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 6px;
    font-size: var(--veryverylittlefont);
  }
}
.text_icon {
  font-weight: 400 !important;
  opacity: 0.9;
  img {
    width: 13px;
    :hover {
      height: 200%;
    }
  }
  .cloud {
    width: 19px;
  }
  .icon_3D {
    padding-top: 12px !important;
    width: 20px;
    cursor: pointer;
  }
  .icon_PDF {
    padding-top: 12px !important;
    width: 28px;
  }
  .icon_puce {
    width: 22px;
    margin: 0px 12px 0px -12px;
  }
  .icon_protec {
    padding-top: 12px !important;
    width: 28px;
  }
  .icon_dashboard {
    padding-top: 12px !important;
    width: 28px;
  }
  .style_icon {
    padding-top: 12px !important;
    width: 28px;
  }
}
.verylittlefonts {
  font-size: var(--veryveryverylittlefont) !important;
}

.virtualbook {
  height: 100%;
  width: 100%;
  padding: 5px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.heightbottom {
  height: 28px;
}
.no_blue:hover {
  color: white;
}

.DojaCat {
  filter: var(--remy);
}
.userzone {
  padding: 0px !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 30px;
  a:hover {
    color: var(--B_W) !important;
    p {
      color: var(--B_W) !important;
      opacity: 0.8;
    }
  }
  .add_user {
    display: flex;
    align-items: center;
    img {
      display: flex;
      filter: var(--checkinvert);
      height: 18px;
      padding: 0 6px 0 0;
    }
    p {
      display: flex;
      align-items: center;
      font-family: "montserrat";
      font-size: calc(0.15em + var(--veryverylittlefont));
      font-weight: 500;
      transition: 0.1s;
    }
  }
}

.dispflex {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-left: 9px;
    margin-right: 9px;
  }
}
.bglogo {
  height: calc(20px + 0.5vw) !important;
  // position: absolute;
  right: 15px;
  top: 15px;
}
.bglogobig {
  height: calc(24px + 0.5vw) !important;
  // position: absolute;
  right: 15px;
  top: 15px;
}
.margintop {
  margin-top: 40px !important;
}
.toolname {
  margin-top: 40px;
  p {
    color: black;
    font-family: "Montserrat";
    font-weight: 500 !important;
    font-size: var(--verybigfont) !important;
  }
}
