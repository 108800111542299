@import "./variable.scss";
.alert {
  .message {
    // Interior
    background-color: rgb(0, 0, 0);
    border-radius: 0px;
    color: white;
    font-size: var(--verylittlefont);
  }
  .alert_box {
    background-color: rgb(0, 0, 0);
    border-radius: 4px;
    color: white;
    font-size: var(--littlefont);
  }

  .progress_bar_succes {
    background: #4149f6;
    height: 2px;
  }
  .progress_bar_error {
    background: rgb(255, 100, 100);
    height: 2px;
  }
  .progress_bar_warning {
    background: #ffea70;
    height: 2px;
  }

  .close_button {
    fill: white;
    height: 20px;
    width: 20px;
    padding: 0px;
    margin-top: 0px;
    margin-left: 4px;
    margin-right: 0px;
  }
}
