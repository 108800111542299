.dispnone {
  display: none;
}
.menuhide {
  display: none !important;
}
.modalbkg {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 80;
  display: flex;
  align-items: center;
  justify-content: center;
  .bkg {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.233);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
  }
  .modal {
    //  height: 550px;
    width: 600px;
    z-index: 2;
    position: absolute;
    background-color: var(--colorgraph);
    .close {
      position: absolute;
      right: 20px;
      top: 15px;
      opacity: 0.7;
      transition: 0.1s;
      cursor: pointer;
      width: 26px;
    }
    .close:hover {
      opacity: 1;
    }
    .title {
      height: 70px;
      display: flex;
      align-items: center;
      padding-left: 30px;
      padding-right: 30px;
      border-bottom: 1px solid #9a9a9a44;
      p {
        font-family: "worksans";
        font-size: var(--regularfont);
        margin: 0;
        padding: 0;
        font-weight: 500;
      }
    }
    .content {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      .oneradio {
        display: flex;
        height: 70px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            font-family: "worksans";
            font-size: var(--medfont);
            margin: 0;
          }
          img {
            width: 22px;
            filter: invert(1);
            margin-left: 20px;
            height: 22px;
            cursor: pointer;
            opacity: 0.8;
            transition: 0.1s;
          }
          img:hover {
            opacity: 1;
          }
        }
        input[type="radio"] {
          width: 20px;
          height: 20px;
          background-color: var(--colorgraph);
          cursor: pointer;
        }
        input[type="radio"]:checked {
          background-color: #e4e800;
        }
        p {
          margin: 0;
        }
        label {
          margin: 0;
        }
      }
      .padding10 {
        margin-top: 10px;
      }
      .inputtext {
        display: flex;
        height: 60px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        label {
          font-family: "worksans";
          font-size: var(--medfont);
          margin: 0;
        }
        input[type="text"] {
          background-color: black;
          border: 1px solid rgb(107, 107, 107);
          box-shadow: none;
          outline: none;
          color: white;
          padding-left: 10px;
          padding-right: 10px;
          height: 32px;
          min-width: 340px;
          font-family: "worksans";
          font-size: var(--littlefont);
        }
        input[type="mail"] {
          background-color: black;
          border: 1px solid rgb(107, 107, 107);
          box-shadow: none;
          outline: none;
          color: white;
          padding-left: 10px;
          padding-right: 10px;
          height: 32px;
          min-width: 340px;
          font-family: "worksans";
          font-size: var(--littlefont);
        }
        input:-internal-autofill-selected {
          // background-color: yellow !important;
        }
      }
      .submit {
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;

        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        height: auto;

        input[type="submit"] {
          background-color: var(--colorgraph);
          border: 1px solid rgb(190, 190, 190);
          box-shadow: none;
          outline: none;
          color: white;
          padding-left: 10px;
          padding-right: 10px;
          font-family: "worksans";
          font-size: var(--littlefont);
          transition: 0.1s;
        }
        input[type="submit"]:hover {
          background-color: var(--bluehover);
          border: 1px solid var(--bluehover);
        }
      }
    }
  }
}
.admin {
  color: #ffffc7;
}
.importzone {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 5px;
}
.import {
  background-color: #464b5c;
  width: fit-content;
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
  p {
    color: white;
    width: auto;
  }
  .contact {
    font-size: calc(10px + 0.2vw);
    font-family: "poppins";
    font-weight: 400;
    width: auto;
    margin: 0;
  }
  .format {
    font-size: calc(8px + 0.2vw);
    font-family: "poppins";
    font-weight: 400;
    margin: 0;
  }
}

.maillist {
  // background-color: rgba(252, 252, 252, 0.336);
  border-top: 1px solid rgba(252, 252, 252, 0.089);

  display: flex;
  flex-wrap: wrap;

  overflow: hidden;
  max-height: 130px;

  .maillistzone {
    margin: 0;
    padding: 0;
    padding-top: 1px;
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
    height: 100%;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 2px;
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    /* overflow: scroll; */
    margin-top: -1px;
    max-height: 130px;
    border-bottom: 1px solid rgba(145, 145, 145, 0.548);

    .onemail {
      border: 1px solid black;
      border-radius: 1000px;
      display: flex;
      flex-wrap: wrap;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 4px !important;
      margin-bottom: 4px !important;
      margin-left: 3px !important;
      margin-right: 3px !important;
      margin: 0;
      align-items: center;
      justify-content: center;
      height: fit-content;
      p {
        padding: 0;
        margin: 0;
        margin-right: 10px;
        cursor: default;
        font-size: calc(10px + 0.2vw);
        font-family: "poppins";
        font-weight: 400;
        height: fit-content;
        white-space: nowrap;
        max-width: 418px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .deletemail {
        width: 10px;
        height: 10px;
        padding: 0;
        margin: 0;
        cursor: pointer;
        display: flex;
        img {
          width: 100%;
          padding: 0;
          margin: 0;
          height: 100%;
        }
      }
    }
    .onemail:hover {
      background-color: rgba(243, 243, 243, 0.034);
    }
  }
  .maillistzone::-webkit-scrollbar:vertical {
    display: block;
    width: 5px;
    border-radius: 20px;
  }
  .maillistzone::-webkit-scrollbar:hover {
    display: block;
    width: 7px !important;
    border-radius: 20px;
  }
  .maillistzone::-webkit-scrollbar-thumb {
    border-right-width: 5px;
    border-radius: 30px;
    background-color: #8f8f8f;
    /* border-top-right-radius: 9px 5px; */
    /* border-bottom-right-radius: 9px 5px; */
  }
  .maillistzone::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
  }
}

select {
  background-color: black;
  color: white;

  border: 1px solid #6b6b6b;
}

.selectApp {
  width: auto;
}
