@import "./home.module.scss";

.container {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  overflow: scroll;
  overflow-x: hidden;
  background-color: var(--bkgtab) !important;
  height: calc(100vh - 60px);
  width: 100%;
  align-content: flex-start;
  z-index: 2;

  .number_status {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 20%;
  }

  .float_left {
    float: left;
  }

  p {
    margin: 0;
    padding: 0;
  }
  span {
    color: var(--select) !important;
  }

  // margin-left: 16vw;
  margin: 0;

  @media (max-width: 1250px) {
    // margin-left: 200px;
    // width: calc(100vw - 200px);
  }

  @media (min-width: 1552px) {
    // margin-left: 240px;
    // width: calc(100vw - 240px) !important;
  }
  .table_container {
    height: 100%;
    width: 100%;
    overflow: hidden;

    .title {
      position: sticky;
      top: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 35px;
      width: 100%;
      background-color: var(--bkgtab) !important;
      padding: 0 30px !important;
      z-index: 10000;

      p {
        padding-top: 2px;
        font-size: var(--littlefont);
        color: var(--B_W);
        text-transform: uppercase;
        font-weight: 500;
      }

      .search {
        width: calc(20% - 32px);
        margin-right: 9px;
        height: 100%;
        display: flex;
        align-items: flex-end;
        input {
          width: 100%;
          height: 28px;
          border: 1px solid rgba(255, 255, 255, 0.4);
          background-color: rgb(0, 0, 0);
          border-radius: 30px;
          color: var(--B_W);
          padding: 0;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    .table::-webkit-scrollbar:vertical {
      display: block;
      width: 5px;
      border-radius: 20px;
    }
    .table::-webkit-scrollbar:hover {
      display: block;
      width: 7px !important;
      border-radius: 20px;
    }
    .table::-webkit-scrollbar-thumb {
      border-right-width: 5px;
      border-radius: 30px;
      background-color: #d9d9d9;
      /* border-top-right-radius: 9px 5px; */
      /* border-bottom-right-radius: 9px 5px; */
    }
    .table::-webkit-scrollbar-thumb:hover {
      background-color: #aaaaaa;
    }
    .table {
      background-color: rgba(255, 196, 0, 0);
      overflow: hidden;
      overflow-x: hidden;
      width: 100%;
      padding: 0px 30px !important;
      height: calc(100% - 60px);
      table {
        background-color: rgba(255, 89, 0, 0);
        width: 100%;
        height: 100%;
        thead {
          tr {
            height: 100% !important;
            width: calc(100% - 50px);
          }
          .nbzone {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 100;
            overflow: visible;

            left: 0;
            top: 0;
            cursor: pointer;
            svg {
              width: 14px;
              path {
                fill: var(--B_W);
              }
            }
          }
        }
        tbody {
          tr:nth-child(2n + 1) {
            background-color: var(--W_B);

            .borderright {
              // display: none;
            }
          }
          tr:nth-child(2n) {
            background-color: var(--bkgtabbody);
          }
          tr:hover {
            background-color: var(--bkgtabbodyhover);
            .borderright {
              // display: none;
            }
          }
          .nbzoneproj {
            width: 50px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            flex: 0 0 60px;
            max-width: 60px;
            position: absolute;
            right: 100px;
            top: 0;
          }
          .nbzonecat {
            width: 50px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            flex: 0 0 60px;
            max-width: 60px;
            position: absolute;
            right: 40px;
            top: 0;
          }
        }
        .desactivated {
          td {
            p {
              opacity: 0.4 !important;
            }
          }
        }
        .desactivateddiv {
          p {
            opacity: 0.4 !important;
          }
        }
        tr {
          transition: 0.2s;
          height: 40px;
          width: 100%;
          padding: 0px !important;
          background-color: rgba(127, 255, 212, 0);
          cursor: pointer;
          position: relative;
          td {
            padding: 0px !important;
            padding: 0px;
            display: flex;
            position: relative;
            width: calc(100% - 50px);
          }
          .desactivate {
            width: calc(40px);
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            top: 0;
            padding-left: calc(0.05vw + 4px);
            svg {
              width: 20px;
              opacity: 0.8;
              path {
                fill: var(--B_W);
              }
            }
          }
          .desactivate:hover {
            svg {
              opacity: 1;
              fill: var(--select);
            }
          }
          .logcontent {
            width: 100% !important;
            display: flex;
            flex-wrap: nowrap;
            margin-right: 0px !important;
            .client {
              padding: 0;
            }
          }
          a:hover {
            color: var(--B_W);
          }
          .logcontent:hover {
            color: var(--B_W) !important;
          }

          .log,
          .logcontent {
            //   margin-right: 2px;

            width: 100%;
            //  margin-bottom: 4px;

            background-color: rgba(0, 0, 255, 0);

            border-radius: 2px 2px 2px 2px;
            .last_log {
              display: flex;
              justify-content: space-between;
              text-transform: uppercase;
              color: var(--B_W);
              font-size: var(--littlefont);
              font-weight: 400;
              display: flex;
              align-items: center;
              padding-left: 25px !important;
              padding-right: 15px !important;
              width: 100%;
              height: 40px;
              background-color: rgb(0, 0, 0);
              border-radius: 2px 2px 2px 2px;

              .status_preventif {
                height: 10px;
                width: 10px;
                background-color: var(--selected);
                border-radius: 30px;
              }

              .status_warning {
                height: 10px;
                width: 10px;
                background-color: #fca73e;
                border-radius: 30px;
              }
              .status_alert {
                height: 10px;
                width: 10px;
                background-color: #fd5554;
                border-radius: 30px;
              }
            }
            .uppercase {
              text-transform: uppercase;
            }
            .message {
              display: flex;
              justify-content: space-between;
              // text-transform: uppercase;
              color: var(--B_W);
              font-size: var(--veryverylittlefont);
              font-weight: 400;
              display: flex;
              align-items: center;
              padding-left: 0px !important;
              padding-right: 0px !important;
              width: 100%;
              height: 40px;

              border-radius: 2px 2px 2px 2px;

              p {
                font-weight: 400;
                text-align: center;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .btn {
                justify-content: center;
                color: var(--B_W);
                font-size: var(--veryveryverylittlefont);
                font-weight: 400;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                width: 110%;

                display: flex;
                height: 30px;
                border-radius: 3px;
                background-color: rgb(0, 0, 0);
                border: 1px solid rgba(255, 255, 255, 0.4);
                &:hover {
                  background-color: rgba(255, 255, 255, 0.25);
                }
              }
            }
          }

          .top {
            width: 100%;
            height: 40px;
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 2px 2px 0px 0px;
          }
          .client {
            .client_name {
              justify-content: space-around;

              font-size: var(--littlefont);
              font-weight: 400;
              text-transform: uppercase;
              display: flex;
              align-items: center;

              width: 100%;
              height: 40px;

              border-radius: 0px 0px 2px 2px;
              padding-left: 5px !important;
            }
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: calc(100% - 10px);
              // padding-right: 15px !important;
              // padding-left: 15px !important;
              font-size: var(--veryverylittlefont);
            }
          }
          .nom {
            justify-content: center;
            color: var(--B_W);
            font-size: var(--littlefont);
            font-weight: 400;

            display: flex;
            align-items: center;

            width: 100%;
            height: 40px;

            border-radius: 0px 0px 2px 2px;
          }

          .machine {
            margin-right: 2px;
            height: 50px;
            width: 15%;
            border-radius: 2px;

            .number {
              color: var(--B_W);
              font-size: var(--veryveryverylittlefont);
              font-weight: 400;
              display: flex;
              align-items: center;
              padding: 0px 15px !important;
              width: 100%;
              height: 20px;
              background-color: rgba(0, 0, 0, 0.2);
              border-radius: 2px 2px 0px 0px;
            }
          }
          .buttons {
            height: 40px;
            width: calc(205px + 0.3vw);
            border-radius: 2px;
            justify-content: center;
            align-content: center;
          }
        }
      }
    }
  }

  .flexend {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .report {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      padding-left: 15px !important;
      padding-right: 15px !important;
      height: 100%;

      div {
        width: calc(100% - 20px);
        height: 100%;

        z-index: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: calc(100%) !important;
          padding-right: 20px !important;
          padding-left: 15px !important;
          font-size: var(--veryverylittlefont) !important;
          font-weight: 400;
          text-align: end;
          top: 0;
          left: 0;
          position: absolute;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
      .colorstatut {
        background-color: var(--B_W);
        width: 12px;
        height: 12px;
        border-radius: 100px;
      }
    }
  }
  .colorjaune {
    color: var(--yellow) !important;
  }
  .externbuttons_all {
    align-items: center;
    display: flex;

    height: 40px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0px 0px 2px 2px;
    position: absolute;
    left: 7px;
    // &:hover {
    //     background-color: rgba(255, 255, 255, 0.25);
    // }

    .dashboard {
      justify-content: center;
      align-items: center;
      margin-right: 2px;
      display: flex;
      height: 27px;
      width: 29px;
      border-radius: 3px;
      position: relative;
      background-color: rgb(0, 0, 0);
      border: 1px solid rgba(255, 255, 255, 0.4);
      a {
        width: 100%;
        height: 100%;
        z-index: 1;
        left: 0;
        top: 0;
        position: absolute;
        a {
          width: 100%;
          height: 100%;
          z-index: 100;
          left: 0;
          top: 0;
          position: absolute;
        }
      }
      img {
        height: 80%;
        width: 80%;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.25);
        cursor: pointer;
      }
    }
    .link {
      justify-content: center;
      align-items: center;
      margin-right: 2px;
      display: flex;
      height: 27px;
      width: 29px;
      border-radius: 3px;
      opacity: 0.3;
      background-color: rgb(0, 0, 0);
      border: 1px solid rgba(255, 255, 255, 0.4);
      img {
        height: 78%;
        width: 78%;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.25);
        cursor: pointer;
      }
    }
    .report {
      justify-content: center;
      color: var(--B_W);
      font-size: var(--veryveryverylittlefont);
      font-weight: 400;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      width: calc(100% - 64px);
      display: flex;
      height: 30px;
      border-radius: 3px;
      background-color: rgb(0, 0, 0);
      border: 1px solid rgba(255, 255, 255, 0.4);
      &:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.25);
      }
    }
  }
  .buttons_all {
    align-items: center;
    display: flex;

    height: 40px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0px 0px 2px 2px;
    // &:hover {
    //     background-color: rgba(255, 255, 255, 0.25);
    // }
    div {
      visibility: hidden;
      opacity: 0;
    }

    .dashboard {
      justify-content: center;
      align-items: center;
      margin-right: 2px;
      display: flex;
      height: 27px;
      width: 29px;
      border-radius: 3px;
      position: relative;
      background-color: rgb(0, 0, 0);
      border: 1px solid rgba(255, 255, 255, 0.4);
      a {
        width: 100%;
        height: 100%;
        z-index: 1;
        left: 0;
        top: 0;
        position: absolute;
        a {
          width: 100%;
          height: 100%;
          z-index: 100;
          left: 0;
          top: 0;
          position: absolute;
        }
      }
      img {
        height: 80%;
        width: 80%;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.25);
        cursor: pointer;
      }
    }
    .link {
      justify-content: center;
      align-items: center;
      margin-right: 2px;
      display: flex;
      height: 27px;
      width: 29px;
      border-radius: 3px;
      background-color: rgb(0, 0, 0);
      border: 1px solid rgba(255, 255, 255, 0.4);
      img {
        height: 78%;
        width: 78%;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.25);
        cursor: pointer;
      }
    }
    .report {
      justify-content: center;
      color: var(--B_W);
      font-size: var(--veryveryverylittlefont);
      font-weight: 400;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      width: calc(100% - 64px);
      display: flex;
      height: 30px;
      border-radius: 3px;
      background-color: rgb(0, 0, 0);
      border: 1px solid rgba(255, 255, 255, 0.4);
      &:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.25);
      }
    }
  }

  .filter_container {
    padding: 0px 30px 0px 30px !important;

    height: 32px;
    width: 100%;
    .margin20 {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    .sorted {
      height: calc(100px + 1vw);
    }
    .sorted,
    .events,
    .machine {
      padding: 0px 0px 0px 0px;
      justify-content: center;
      flex-wrap: wrap;

      color: var(--B_W);
      select {
        margin-right: 30px;
        background-color: rgb(43, 43, 43);
        color: var(--B_W);
        outline: none;
      }

      p {
        margin: 4px 0px;
        padding-bottom: -10px;
        display: flex;
        width: 100%;
        height: 20%;
        font-size: var(--veryveryverylittlefont);
        text-transform: uppercase;
        font-weight: 400;
        height: auto;
      }
      ul {
        padding: 0 0px;
        margin: 0px;
        list-style: none;
        width: 100%;
        font-size: var(--veryveryverylittlefont);
        li {
          justify-content: center;
          padding: 4px 0px 4px 0px;
          align-content: center;

          input {
            position: relative;
            top: 2px;
            width: 14px;
            height: 14px;
            margin: 0px 8px 0px 0px;
            padding: 0px;
          }
          label {
            margin: 0px;
            padding: 0px;
            padding-bottom: 0px;
          }
        }
        .first {
          border-top: 1px solid rgba(255, 255, 255, 0.3);
          padding-top: 10px;
        }
        margin-top: 10px;
      }
    }
    .filter {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding-top: 2px;

      height: calc(100%);
      width: 100%;
      color: var(--B_W);

      p {
        margin: 4px 0px;
        padding-bottom: -10px;
        display: flex;
        width: 100%;

        font-size: var(--veryveryverylittlefont);
        text-transform: uppercase;
        font-weight: 400;
      }
      .date {
        width: 100%;

        p {
          width: auto;

          padding-top: 7px;
          padding-bottom: 7px;
        }
        ul {
          margin: 0;
          padding: 0;
          width: 100%;
          margin-bottom: 17px;
        }
        li {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          padding-top: 2px;
          padding-bottom: 2px;
          p {
            width: auto;
            margin: 0;
            padding: 0;
          }
          input {
            max-width: 140px;
            width: calc(100% - 45px);

            border: 1px solid #6b6b6b;
            box-shadow: none;
            outline: none;

            padding-left: 7px;
            padding-right: 7px;
            height: 24px;
            font-weight: 400;

            font-size: var(--veryveryverylittlefont);
            background-repeat: no-repeat;
            right: 0;
            background-color: rgb(238, 238, 238);
            -webkit-filter: invert(1);
            filter: invert(1);
            color: #000;
            &::-webkit-datetime-edit {
              padding: 0;
              margin: 0;
            }
            &::-webkit-datetime-edit-text {
              padding: 0 0 0 0;
            }
          }
          input[type="date"]::-webkit-calendar-picker-indicator {
            //display: none;
            //-webkit-appearance: none;
            width: 15px;
            padding-left: 7px;
            margin: 0px;
            margin-top: 4px;
          }
        }
      }
    }
  }
}

.title {
  padding-top: 10px !important;
}
.first {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 10px;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  border-radius: 2px;
  outline: none;
  border: 1px solid #afafaf;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  left: 0;
  position: relative;
  border: 1px solid #afafaf;
  cursor: pointer;
}
[type="checkbox"]:not(:checked) {
  background-color: transparent;
  border: 1px solid #afafaf;
}
[type="checkbox"]:checked {
  background-image: url(../../images/icon/check.svg);
  background-size: 95%;
  filter: invert(1);
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #444444;
  padding: 0;
}
.displaynone {
  display: none !important;
}
.infosmachine {
  position: fixed;
  left: 0;
  top: 65px;
  z-index: 10000000000000;
  width: 100%;
  height: calc(100% - 5px);
  background-color: black;
}

.Allproject_project_section {
  margin: 0;
  height: 100%;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;

  margin-bottom: 5px;
  text-align: center;

  border-top: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  .Allproject_projects_content {
    margin: 0;
    padding-right: 0px;
    padding-left: 0px;

    height: calc(100% - calc(47px + 1vw));

    overflow: scroll;

    margin-bottom: 4px;
    align-content: flex-start;
    display: flex;
    background-color: var(--W_B);
    padding-left: 5px !important;

    border-left: 1px solid#505050;
    border-right: 1px solid#505050;
    border-bottom: 1px solid#505050;
  }
  .Allproject_projects_content::-webkit-scrollbar:vertical {
    display: block;
    width: 5px;
    border-radius: 20px;
  }
  .Allproject_projects_content::-webkit-scrollbar:hover {
    display: block;
    width: 7px !important;
    border-radius: 20px;
  }
  .Allproject_projects_content::-webkit-scrollbar-thumb {
    border-right-width: 5px;
    border-radius: 30px;
    background-color: #d9d9d9;
    /* border-top-right-radius: 9px 5px; */
    /* border-bottom-right-radius: 9px 5px; */
  }
  .Allproject_projects_content::-webkit-scrollbar-thumb:hover {
    background-color: #aaaaaa;
  }
}
.borderright {
  border-right: 1px solid #5a5b5d;
  height: 100%;
  position: absolute;
  right: 0;
  opacity: 0.55;
}
.Allproject_projects_section_sort {
  background-color: var(--bkgtabhead);
  text-align: center;
  display: flex;

  border: 1px solid #505050;

  border-radius: 4px 4px 0px 0px;

  font-size: $font9;
  font-family: "worksans";
  font-weight: 400;
  color: black;
  align-items: center;
  height: calc(15px + 1vw);
  padding-left: 5px !important;
  padding-right: 4px !important;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  .sort_options {
    width: calc(22px + 0.4vw);

    display: flex;
    align-items: center;
    position: absolute;
    left: 3.4%;
    z-index: 1;
    button {
      border: 1px solid #777777;
      background-color: white;
      width: 100%;
      height: calc(19px + 0.4vw);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      padding: 0;
      margin-top: -14px;
      img {
        width: 45%;
      }
    }
  }
  .sort_list_active {
    display: block !important;
  }
  .sort_list {
    display: none;
    background-color: white;
    box-shadow: 3px 5px 7px #d4d4d4;
    li {
      padding-left: 15px;
      padding-top: 7px;
      padding-bottom: 7px;
      cursor: pointer;
    }
    li:hover {
      color: $blue;
      transform: scale(1.01);
    }
    .sort_active {
      color: $blue;
    }
  }
}
.Allproject_padding {
  width: 100%;
  display: flex;
  // padding-right: 10px;
  // padding-left: 10px;
  background-color: #151515;
  display: flex;
  align-items: center;
  border-radius: 0px;
  .paddingleft {
    padding-left: 62px !important;
  }
  .Allproject_projects_sort {
    text-align: center;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;

    // background-color: var(--toptable);
    height: calc(100%);

    font-size: var(--veryverylittlefonttable);
    font-weight: 400;
    color: var(--colortext);
    font-family: "worksans";
    padding-left: 5px;
    padding-right: 5px;
    span {
      img {
        margin-left: 10px;
        width: 10px;
        opacity: 0.5;
        margin-bottom: 3px;
      }
    }

    p {
      color: var(--colortext);
      font-size: var(--veryverylittlefonttable);
      font-weight: 400;
      margin: 0;
      padding: 0;
      font-family: "worksans";
    }
  }
  th {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    // text-transform: uppercase;
  }
  .overflowvisible {
    overflow: visible;
    p {
      overflow: visible;
      width: 100%;
      height: 100%;
    }
  }

  .Allproject_projects_sort:last-child {
    border-right: 1.5px solid transparent;
  }
  button {
    background-color: var(--colorprinc);
    border-right: 1.5px solid #dee1e6;
    border: none;
    font-family: "worksans";
  }
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: black;
  color: white;
  font-size: var(--veryverylittlefont);
  position: absolute;
  padding: 2px 10px 2px 10px;
  bottom: -18px;
  right: 20px;
  white-space: nowrap;
  border-radius: 6px;

  opacity: 0;
  border: none;
  z-index: 10;
  visibility: hidden;
  font-family: "montserrat";
  font-weight: 500;
}
[data-title] {
  position: relative;
}
.status_preventif {
  height: calc(100% - 2px);
  margin-top: 1px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 3px;
  background-color: var(--selected);

  left: 0;
  position: absolute;
  top: 0;
}

.col1 {
  flex: 0 0 3%;
  max-width: 3%;
}
.col3 {
  flex: 0 0 10%;
  max-width: 10%;
}
.col4 {
  flex: 0 0 12%;
  max-width: 12%;
}
.col5 {
  flex: 0 0 24%;
  max-width: 24%;
}

.borderright {
  position: absolute;
  right: 0;
  height: 100%;
  width: 1px;
  background-color: var(--borderbtn);
}
.log,
.client {
  position: relative !important;
}
